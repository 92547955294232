/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum AlertThresholdTypeKind {
  CHANGE_COUNT_ALERT = 'change-count-alert',
  CHANGE_PERCENTAGE_ALERT = 'change-percentage-alert',
  CHANGE_SIGNIFICANCE_ALERT = 'change-significance-alert',
}
