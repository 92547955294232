import {
  ColumnKind,
  type ContainerAvailableFiltersResponse,
  FilterKind,
  FiltersService,
  type GenericFilterRequest,
  useCustomFetch,
} from '@/api'
import { EFilterType, type TFilterConfig } from '@/components/filters'
import { type Ref, capitalize, computed } from 'vue'
import { type TGenericFilterWithResponse } from './filter-helpers'
import { createFilterRequest, encodeFilterKey, getFilterValue, isEmptyFilter, mapFilterType } from './filter-helpers'
import { tolgee } from '@/plugins/i18n'
import { useProjectId } from '@/views/reports/report-context'
import { useTranslate } from '@tolgee/vue'
import type { TFilters } from '@/components/filters'

export const useProjectFiltersConfig = ({
  appliedFilters,
  availableFilters,
  getFilter,
  isLoadingAvailableFilters,
  showOnlyAuxiliaryFilters,
}: {
  appliedFilters: Ref<GenericFilterRequest[]>
  availableFilters: Ref<ContainerAvailableFiltersResponse | undefined>
  getFilter: (key: string) => TGenericFilterWithResponse
  isLoadingAvailableFilters: Ref<boolean>
  showOnlyAuxiliaryFilters?: boolean
}) => {
  const projectId = useProjectId()
  const { t } = useTranslate()
  const { fetch } = useCustomFetch(FiltersService.projectFiltersValuesGet)

  const fetchFilterValues = async (colRef: string, kind: FilterKind, type: EFilterType, searchValue?: string) => {
    const searchFilter = searchValue ? createFilterRequest('text-value-contains', colRef, searchValue) : undefined
    const filters = appliedFilters.value.filter((f) => f.column_ref !== colRef) || []

    if (searchFilter) {
      filters.push(searchFilter)
    }

    const res = await fetch({
      projectId,
      requestBody: {
        column_ref: colRef,
        filter_kind: kind,
        filters,
      },
    })

    if (type === EFilterType.Range) return res?.values

    return {
      values: (res?.values || []).map((value) => ({
        value: String(value),
        label: capitalize(String(value).replace(/-|_/g, ' ')),
        disabled: false,
      })),
      hasMoreValues: res?.has_more_values || false,
    }
  }

  return {
    isLoadingAvailableFilters,
    projectFiltersConfig: computed<TFilters | undefined>(() => {
      if (!availableFilters.value) return undefined

      const columns = Object.entries(availableFilters.value.columns)
        .filter(([, filter]) => !showOnlyAuxiliaryFilters || filter.type !== ColumnKind.TEXT_TO_ANALYZE)
        .map(([colRef, filter]) => ({
          ref: colRef,
          name: filter.name,
          filterKinds: filter.available_filters,
          favorite: filter.is_favorite,
        }))

      const favoriteColumns = columns.filter((col) => col.favorite)
      const nonFavoriteColumns = columns.filter((col) => !col.favorite)

      // backend return an object with column ref as key and array of filter kind as value
      // we map it to array of filter config here
      const createFilterConfig = (filters: TFilters, col: { ref: string; name: string }) => {
        const colFilter = availableFilters.value?.filters[col.ref]

        if (!colFilter) return filters

        return [
          ...filters,
          ...colFilter.map<TFilterConfig>((kind) => {
            const key = encodeFilterKey(kind, col.ref)
            const filter = getFilter(key)
            const { type, labelTranslationKey, props } = mapFilterType(kind)
            const value = filter ? getFilterValue(filter) : undefined

            return {
              type,
              key,
              lazy: true,
              description:
                type === EFilterType.Checkbox
                  ? t.value('reports.report_detail.insight_filters.choices_sorted_by_descending_occurrence')
                  : undefined,
              isEmpty: filter ? isEmptyFilter(filter) : false,
              fetchValues: (searchValue?: string) => fetchFilterValues(col.ref, kind, type, searchValue),
              label: labelTranslationKey ? t.value(labelTranslationKey, { col: col.name }) : col.name,
              loading: filter?.loading || false,
              value,
              ...props,
              searchItemKey: 'label',
              renderCheckboxContent: (item) => {
                return item.label
              },
            } as TFilterConfig
          }),
        ]
      }

      return [
        ...(favoriteColumns.length
          ? [
              {
                type: EFilterType.Seperator,
                key: 'favorite_filters_seperator',
                label: tolgee.t('projects.favorite_filters'),
                description: tolgee.t('projects.favorite_columns_description'),
              } as TFilterConfig,
            ]
          : []),
        ...favoriteColumns.reduce<TFilters>(createFilterConfig, []),
        ...(favoriteColumns.length
          ? [
              {
                type: EFilterType.Seperator,
                key: 'other_filters_seperator',
                label: tolgee.t('projects.other_filters'),
              } as TFilterConfig,
            ]
          : []),
        ...nonFavoriteColumns.reduce<TFilters>(createFilterConfig, []),
      ]
    }),
  }
}
