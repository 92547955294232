import {
  ApiError,
  type Code,
  DebounceDelay,
  type Metadata,
  type ProjectCodingRowTextUIResponse,
  type ProjectCodingRowTextUpdateUIRequest,
  type ProjectCodingRowUIResponse,
  ProjectDetailUIResponse,
  ProjectsService,
  type RowTopic,
  TextToAnalyzeFieldUIResponse,
  TextToAnalyzeMetadataUIResponse,
  type TextToAnalyzeStatsResponse,
  type TopicAssignmentWorkerUserOnline,
  TopicMergeRequest,
  type TopicUIRequest,
  type TopicUIResponse,
  type TopicUpdateRequest,
  getErrorMsg,
} from '@/api'

import {
  type ArgTypes,
  useCodeProjectRowMutation,
  useProjectUpdateMutation,
  useTopicUpdateMutation,
  useTopicsMergeMutation,
} from '@/api/vq/projects'
import { computed, nextTick, ref, watch } from 'vue'
import { parseQuestions } from '@/utils'
import { queryClient } from '@/plugins/vue-query'
import { useCreateDiscardedTopicMutation, useDeleteDiscardedTopicMutation } from '@/api/vq/topicer'
import { useEventBus } from '@/composables/useEventBus'
import { useProjectPermissionProtectedRef } from './useUserProjectPermissions'
import { useRoute } from 'vue-router'
import { useUrlSearchParams } from '@vueuse/core'

export type TVirtualRow = {
  render_index: number
  data?: ProjectCodingRowUIResponse
}

export const categoryColors = [
  'rgba(54, 162, 235, 0.75)',
  'rgba(255, 99, 132, 0.75)',
  'rgba(75, 192, 192, 0.75)',
  'rgba(153, 102, 255, 0.75)',
  'rgba(255, 159, 64, 0.75)',
  'rgba(121, 85, 72, 0.75)',
  'rgba(78, 78, 225, 0.75)',
  'rgba(74, 214, 113, 0.75)',
]

export type TTopicAttitude = keyof Pick<
  TopicUIResponse,
  'sentiment_negative' | 'sentiment_neutral' | 'sentiment_positive'
>

export const TOPIC_ATTITUDES: TTopicAttitude[] = ['sentiment_negative', 'sentiment_neutral', 'sentiment_positive']

// internal function to parse
export const parseCategories = (topics: TopicUIResponse[] | undefined) => {
  const categoryMap = new Map<string, TCategory>()

  // check if there are topics
  if (!topics) return []

  topics.forEach((topic) => {
    if (!categoryMap.has(topic.category)) {
      categoryMap.set(topic.category, {
        label: topic.category,
        color: topic.color_from_palette,
        topics: [topic],
      })
    } else {
      categoryMap.get(topic.category)?.topics.push(topic)
    }
  })

  // return categories as array
  return Array.from(categoryMap.values())
}

export const getTopicSelectOptions = (topics: TopicUIResponse[] | undefined, idValues: string[] | undefined) => {
  const categories = parseCategories(topics)
  const categoriesDict: Record<string, TCategory> = categories.reduce((obj, cat) => ({ ...obj, [cat.label]: cat }), {})
  const filteredOptions: Record<string, TCategory> = {}

  Object.keys(categoriesDict).forEach((key) => {
    const filteredTopics = categoriesDict[key].topics.filter((topic) => !idValues || !idValues.includes(topic.id))

    if (filteredTopics.length) {
      filteredOptions[key] = {
        ...categoriesDict[key],
        topics: filteredTopics,
      }
    }
  })

  return filteredOptions
}

export const getTopicSelectValue = (
  idValues: string[] | undefined,
  topics: TopicUIResponse[] | undefined
): RowTopic[] => {
  if (!idValues || !idValues.length || !topics || !topics.length) return []

  return idValues
    .map((id) => {
      const topic = topics.find((t) => t.id === id.split(':')[0])

      return {
        ...topic,
        sentiment: id.split(':')[1] || null,
      }
    })
    .filter((t) => t) as unknown as RowTopic[]
}

const getRequestBodyFromRow = (
  row: ProjectCodingRowUIResponse,
  update_identicals: boolean
): ProjectCodingRowTextUpdateUIRequest => {
  return {
    was_reviewed: row.text_to_analyze.was_reviewed,
    highlight: row.text_to_analyze.highlight || null,
    update_identicals,
    client_timestamp: new Date().toISOString(),
    topics: row.text_to_analyze.topics.map((topic) => ({
      id: topic.id,
      sentiment: (topic.sentiment || 'neutral') as unknown as TopicUpdateRequest.sentiment,
    })),
  } as ProjectCodingRowTextUpdateUIRequest
  // TODO: once backend generated schema accepts null, remove this type cast ^
}

function createCodingComposable(projectID: string) {
  const currentRoute = useRoute()
  const URLParams: Partial<ArgTypes<'projectCodingRowList'>> & { assistant?: boolean } = useUrlSearchParams()
  const eventBus = useEventBus()
  const { mutateAsync: updateProjectAsync } = useProjectUpdateMutation()
  const topicsEditorModalVisible = ref<boolean>(!!URLParams.assistant)

  // state
  const project = ref<ProjectDetailUIResponse>()
  const questions = ref<ReturnType<typeof parseQuestions>>()

  // topic assignment worker state
  const userOnline = ref<TopicAssignmentWorkerUserOnline>()
  const readonly = useProjectPermissionProtectedRef({
    value: false,
    projectId: projectID,
    permission: 'projects_edit',
    reversed: true,
  })
  const reviewsUntilUpdate = ref(0)
  const aiUpdating = ref(false)
  const codingUpdateETA = ref<string>()
  const updatingRawTopicCollection = ref(false)
  const isRedoingTopicCollection = ref(false)

  const isExporting = ref(false)

  // question getters
  const analyzeQuestions = computed(() => questions.value?.analyze || [])
  const auxiliaryQuestions = computed(() => questions.value?.auxiliary || [])

  // selected question/column
  const selectedQuestionRef = ref<string | undefined>(undefined)

  const selectedQuestion = ref<TextToAnalyzeFieldUIResponse>()
  const selectedQuestionStats = ref<TextToAnalyzeStatsResponse>()
  const isQuestionSemiOpen = computed(
    () => selectedQuestion.value?.metadata?.category === TextToAnalyzeMetadataUIResponse.category.LIST_ANSWERS
  )

  // Ai quality score
  const modelScore = computed(() =>
    selectedQuestionStats.value?.model_score && selectedQuestionStats.value.model_score >= 0
      ? Math.round(selectedQuestionStats.value.model_score * 100)
      : -1
  )

  // row state
  const rowList = ref<Array<TVirtualRow>>([])
  const selectedRowIndex = ref<number | undefined>(undefined)
  const selectedRow = computed(
    () => Number.isInteger(selectedRowIndex.value) && rowList.value[selectedRowIndex.value || 0]?.data
  )
  const mutatingRowId = ref<boolean | string>(false)
  const codingDrawerVisible = computed(
    () => !!selectedRow.value && !allRowsSelected.value && !checkedRowIds.value.length
  )
  const newTopicModalVisible = ref(false)

  // metadata
  const groupIdentical = computed(() => !!selectedQuestion.value?.metadata?.do_group_duplicates)
  const aiUpdates = computed(() => !!selectedQuestion.value?.metadata?.do_ai_updates)
  const showTranslations = computed(() => !!selectedQuestion.value?.metadata?.do_show_translations)

  // bulk assign state
  const checkedRowIds = ref<Array<string>>([])
  const allRowsSelected = ref(false)

  // focus mode state
  const focusModeVisible = ref(false)

  // topics editor modal state
  const hoveredReduceSuggestionTopics = ref<Record<string, Code>>({})
  const activeReduceSuggestionTopics = ref<Record<string, Code>>({})

  // updated values depending on question
  const currentTopics = computed<TopicUIResponse[] | undefined>(() => selectedQuestion.value?.topics)
  const currentCategories = ref<TCategory[]>([])

  // Getters for topic/category dict
  const currentTopicsDict = computed<Record<string, TopicUIResponse>>(() => {
    if (!currentTopics.value?.length) return {}

    return currentTopics.value.reduce((obj, topic) => ({ ...obj, [topic.id]: topic }), {})
  })
  // TO DO: change label -> id when categories are entities
  const currentCategoriesDict = computed<Record<string, TCategory>>(() => {
    if (!currentCategories.value?.length) return {}

    return currentCategories.value.reduce((obj, cat) => ({ ...obj, [cat.label]: cat }), {})
  })

  // topic related utilities
  const lockedTopicID = ref<string>()
  const topicCodesInUse = computed(() => {
    const ids: number[] = []

    currentTopics.value?.map((topic) => {
      if (topic.sentiment_enabled) ids.push(...TOPIC_ATTITUDES.map((att) => topic[att].code))
      else ids.push(topic.sentiment_neutral.code)
    })

    return new Set(ids.sort((a, b) => a - b))
  })

  const nextAvailableTopicCode = computed(() => {
    const code = [...topicCodesInUse.value.values()].pop()

    return typeof code === 'number' ? code + 1 : 0
  })

  const hasAnyDisabledSentimentTopics = computed(() => {
    const topics = currentTopics.value || []

    return topics.some((topic) => !topic.sentiment_enabled)
  })

  const hasAnyEnabledSentimentTopics = computed(() => {
    const topics = currentTopics.value || []

    return topics.some((topic) => topic.sentiment_enabled)
  })

  const mostlySentimentEnabled = computed(() => {
    const topics = currentTopics.value || []
    const sentimentEnabledCount = topics.filter((t) => t.sentiment_enabled).length

    return sentimentEnabledCount >= topics.length / 2
  })

  // for loading status
  const isFetchingProject = ref(false)
  const isFetchingColumnDetail = ref(false)
  const isFetchingColumnStats = ref(false)
  const isFetching = computed(
    () => isFetchingProject.value || isFetchingColumnDetail.value || isFetchingColumnStats.value
  )

  const fetchProject = async () => {
    isFetchingProject.value = true
    try {
      const res = await ProjectsService.project({ id: projectID })

      project.value = res
      questions.value = parseQuestions(res.columns)

      // set a default question if it doesn't exist and there is text_to_analyze kind of question
      if (!selectedQuestionRef.value && analyzeQuestions.value.length > 0) {
        // NOTE: this value is watched and triggers fetchColumnDetail
        selectedQuestionRef.value = (currentRoute.params.questionRef as string) || analyzeQuestions.value[0].ref
      }
    } catch (err) {
      window.$message.error(getErrorMsg(err as ApiError))
    } finally {
      isFetchingProject.value = false
    }
  }

  // this is the main function for fetching latest topic state
  const fetchColumnDetail = async () => {
    if (!selectedQuestionRef.value) {
      console.warn('There is not any question/column selected, skipping request')
      return
    }

    isFetchingColumnDetail.value = true

    try {
      const res = await ProjectsService.projectColumnDetails({
        id: projectID,
        ref: selectedQuestionRef.value,
      })

      selectedQuestion.value = res.payload as TextToAnalyzeFieldUIResponse

      // update categories / needs to be done manually
      currentCategories.value = parseCategories(selectedQuestion.value?.topics)
    } catch (err) {
      window.$message.error(getErrorMsg(err as ApiError))
    } finally {
      isFetchingColumnDetail.value = false
    }
  }

  const fetchColumnStats = async () => {
    if (!selectedQuestionRef.value) {
      console.warn('There is not any question/column selected, skipping request')
      return
    }

    isFetchingColumnStats.value = true

    try {
      const res = await ProjectsService.projectQuestionStats({
        id: projectID,
        ref: selectedQuestionRef.value,
      })

      selectedQuestionStats.value = res
    } catch (err) {
      window.$message.error(getErrorMsg(err as ApiError))
    } finally {
      isFetchingColumnStats.value = false
    }
  }

  const { mutateAsync: updateTopicsRequest, isPending: isUpdatingTopics } = useTopicUpdateMutation(projectID)
  const { mutateAsync: mergeTopicsRequest } = useTopicsMergeMutation(projectID)
  const { mutateAsync: codeProjectRow } = useCodeProjectRowMutation()
  const { mutateAsync: createDiscardedTopic } = useCreateDiscardedTopicMutation()
  const { mutateAsync: deleteDiscardedTopic } = useDeleteDiscardedTopicMutation()

  const updateTopics = async (topics: TopicUIRequest[]) => {
    if (!selectedQuestionRef.value) {
      console.warn('There is not any question/column selected, skipping request')
      return undefined
    }

    if (readonly.value) {
      console.warn('The project is in readonly mode, skipping request')
      return undefined
    }

    const res = await updateTopicsRequest({
      id: projectID,
      ref: selectedQuestionRef.value,
      requestBody: {
        inference_training_debounce_delay: currentRoute.query.showEditor
          ? DebounceDelay.LONG_DELAY
          : DebounceDelay.DEFAULT,
        topics,
      },
    })

    await fetchColumnDetail()
    // always emit event to update other components
    eventBus.emit('topics-update', topics)

    return res
  }

  const mergeTopics = async (requestBody: TopicMergeRequest) => {
    if (!selectedQuestionRef.value) {
      console.warn('There is not any question/column selected, skipping request')
      return undefined
    }

    const res = await mergeTopicsRequest({
      id: projectID,
      ref: selectedQuestionRef.value,
      requestBody,
    })

    await fetchColumnDetail()

    return res
  }

  // delete operation
  const deleteTopics = async (topicIds: string[]) => {
    if (!currentTopics.value || !selectedQuestionRef.value) return

    const topics = currentTopics.value.filter((t) => !topicIds.includes(t.id))

    eventBus.emit('topics-delete', topicIds)
    await updateTopics(topics)
    queryClient.invalidateQueries({ queryKey: ['list-discarded-topics'] })
  }

  const updateRow = async (
    row: ProjectCodingRowUIResponse,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback?: (...args: any) => void,
    optimistic = true
  ) => {
    if (readonly.value) {
      console.warn('The project is in readonly mode, skipping request')
      return undefined
    }

    if (!selectedQuestionRef.value) {
      console.warn('There is not any question/column selected, skipping request')
      return undefined
    }

    if (mutatingRowId.value) {
      console.log('A row is already being updated, skipping request')
      return undefined
    }

    // optimistic update row
    const rowToUpdate = rowList.value.find((item) => item.data?.id === row.id)
    let rowBackup: TVirtualRow['data'] = undefined

    if (optimistic) {
      if (rowToUpdate && rowToUpdate.data) {
        // take a backup first
        rowBackup = rowToUpdate.data
        rowToUpdate.data = row
      }
    }

    nextTick(() => (mutatingRowId.value = row.id))

    const res = await codeProjectRow(
      {
        pId: projectID,
        ref: selectedQuestionRef.value,
        rId: row.id,
        requestBody: getRequestBodyFromRow(row, groupIdentical.value),
      },
      {
        onSuccess: callback,
        onError: () => {
          if (optimistic && rowToUpdate && rowBackup) rowToUpdate.data = rowBackup
        },
        onSettled: () => (mutatingRowId.value = false),
      }
    )

    await fetchColumnDetail()

    return res
  }

  const handleRowReview = async () => {
    if (!selectedRow.value) return

    if (selectedRow.value.text_to_analyze.was_reviewed) {
      selectedRowIndex.value = (selectedRowIndex.value || 0) + 1

      return
    }

    await updateRow(
      {
        ...selectedRow.value,
        text_to_analyze: {
          ...selectedRow.value.text_to_analyze,
          was_reviewed: true,
        },
      },
      updateListRow
    )

    selectedRowIndex.value = (selectedRowIndex.value || 0) + 1
  }

  const updateListRow = (res: ProjectCodingRowTextUIResponse) => {
    //  In the row browser I'm making sure to not unload the page that the selectedRowIndex is on.
    const item = rowList.value[selectedRowIndex.value || 0]

    if (!item || !item.data) return
    item.data.text_to_analyze = res
  }

  // needs to be initialized from the PARENT component once
  const init = async () => {
    try {
      await fetchProject()
    } catch (err) {
      window.$message.error(getErrorMsg(err as ApiError))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateQuestionMetadata = async (key: keyof Metadata, value: any) => {
    if (!selectedQuestion.value || !selectedQuestionRef.value) {
      console.warn('No question selected, skipping update')
      return
    }

    if (readonly.value) {
      console.warn('The project is in readonly mode, skipping request')
      return
    }

    try {
      // Update project column metadata
      await updateProjectAsync({
        id: projectID,
        requestBody: {
          columns: [
            {
              ref: selectedQuestion.value.ref,
              name: selectedQuestion.value.name,
              type: TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE,
              description: selectedQuestion.value.description,
              metadata: {
                [key]: value,
              },
            },
          ],
        },
      })

      await fetchColumnDetail()
    } catch (error) {
      console.error('Error updating metadata:', error)
      throw error
    }
  }

  /* WATCHERS */
  watch(selectedQuestionRef, async (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) {
      await fetchColumnDetail()
      await fetchColumnStats()
    }
  })

  return {
    init,
    fetchProject,
    fetchColumnDetail,
    updateTopics,
    mergeTopics,
    deleteTopics,
    updateRow,
    updateListRow,
    createDiscardedTopic,
    deleteDiscardedTopic,
    handleRowReview,
    fetchColumnStats,
    topicsEditorModalVisible,

    readonly,
    userOnline,
    reviewsUntilUpdate,
    aiUpdating,
    codingUpdateETA,
    updatingRawTopicCollection,

    isExporting,

    project,
    questions,
    analyzeQuestions,
    auxiliaryQuestions,

    currentTopics,
    currentTopicsDict,
    currentCategories,
    currentCategoriesDict,
    selectedQuestion,
    selectedQuestionStats,
    selectedQuestionRef,
    isUpdatingTopics,
    isRedoingTopicCollection,

    // rows
    rowList,
    mutatingRowId,
    selectedRowIndex,
    selectedRow,
    codingDrawerVisible,
    newTopicModalVisible,
    groupIdentical,
    showTranslations,
    aiUpdates,

    // bulk assign
    checkedRowIds,
    allRowsSelected,

    // focus mode
    focusModeVisible,

    // topics editor
    hoveredReduceSuggestionTopics,
    activeReduceSuggestionTopics,
    mostlySentimentEnabled,
    hasAnyDisabledSentimentTopics,
    hasAnyEnabledSentimentTopics,

    // utility
    lockedTopicID,
    topicCodesInUse,
    isQuestionSemiOpen,
    nextAvailableTopicCode,
    URLParams,
    modelScore,

    // loading statuses
    isFetching,
    isFetchingProject,
    isFetchingColumnDetail,
    isFetchingColumnStats,

    updateQuestionMetadata,
  }
}

// holder of all coding stores
const codingStoreCache: Record<string, ReturnType<typeof createCodingComposable>> = {}

export const useCoding = (projectID: string) => {
  // create if it is not in cache already
  if (!codingStoreCache[`coding_${projectID}`]) {
    codingStoreCache[`coding_${projectID}`] = createCodingComposable(projectID)
  }

  // return from cache
  return codingStoreCache[`coding_${projectID}`]
}
