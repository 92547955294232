<template>
  <div class="flex w-full items-center justify-end">
    <NSelect
      :value="thresholdType"
      :options="thresholdTypeOptions"
      placeholder="Select threshold"
      class="mr-2 max-w-[180px] shrink-0"
      :consistent-menu-width="false"
      @update:value="handleTypeChange"
    />
    <NInputNumber
      :value="thresholdValue"
      class="max-w-[180px] shrink-0"
      :show-button="false"
      :min="thresholdType === AlertThresholdTypeKind.CHANGE_SIGNIFICANCE_ALERT ? 50 : 0"
      :max="
        thresholdType === AlertThresholdTypeKind.CHANGE_PERCENTAGE_ALERT ||
        thresholdType === AlertThresholdTypeKind.CHANGE_SIGNIFICANCE_ALERT
          ? 99
          : undefined
      "
      @update:value="handleValueChange"
    >
      <template #suffix>
        <span v-if="thresholdType === AlertThresholdTypeKind.CHANGE_PERCENTAGE_ALERT" class="ml-2 text-xs">%</span>
        <span v-else-if="thresholdType === AlertThresholdTypeKind.CHANGE_COUNT_ALERT" class="ml-2 text-xs">times</span>
        <span v-else class="ml-2 text-xs">(50-99)</span>
      </template>
    </NInputNumber>
  </div>
</template>

<script setup lang="ts">
import { AlertOnTopicTrendsPublicType, AlertThresholdTypeKind } from '@/api'
import { computed } from 'vue'
import { map } from 'lodash-es'
import { useTranslate } from '@tolgee/vue'

const props = defineProps<{
  alertType: AlertOnTopicTrendsPublicType
  thresholdType: AlertThresholdTypeKind
  thresholdValue: number
}>()

const emit = defineEmits<{
  'update:threshold-type': [value: AlertThresholdTypeKind]
  'update:threshold-value': [value: number]
}>()

const { t } = useTranslate()

const thresholdTypeOptions = computed(() =>
  map(AlertThresholdTypeKind, (kind) => ({
    label: t.value(`projects.alerts_view.threshold.${kind}`),
    value: kind,
    disabled:
      kind === AlertThresholdTypeKind.CHANGE_SIGNIFICANCE_ALERT &&
      props.alertType === AlertOnTopicTrendsPublicType.OCCURRENCES,
  }))
)

const handleTypeChange = (value: AlertThresholdTypeKind) => {
  emit('update:threshold-type', value)
  emit('update:threshold-value', 50)
}

const handleValueChange = (value: number | null) => {
  emit('update:threshold-value', value || 0)
}
</script>
