/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum CountDisplayKind {
  NOT_SET = 'not_set',
  NUMBERS = 'numbers',
  PERCENTAGES = 'percentages',
}
