/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OverallSentimentWoTimeInsightElementUpdateRequest = {
  kind?: 'OverallSentimentWoTimeInsight';
  order_id?: number;
  visible_left?: OverallSentimentWoTimeInsightElementUpdateRequest.visible_left;
  visible_center?: OverallSentimentWoTimeInsightElementUpdateRequest.visible_center;
  visible_right?: OverallSentimentWoTimeInsightElementUpdateRequest.visible_right;
};
export namespace OverallSentimentWoTimeInsightElementUpdateRequest {
  export enum visible_left {
    SENTIMENT_SCORE_GAUGE = 'sentiment_score_gauge',
    SENTIMENT_SCORE_SEGMENTED_BAR_CHART = 'sentiment_score_segmented_bar_chart',
  }
  export enum visible_center {
    POSITIVE_SENTIMENT_ROWS = 'positive_sentiment_rows',
    POSITIVE_SENTIMENT_SUMMARY = 'positive_sentiment_summary',
  }
  export enum visible_right {
    NEGATIVE_SENTIMENT_ROWS = 'negative_sentiment_rows',
    NEGATIVE_SENTIMENT_SUMMARY = 'negative_sentiment_summary',
  }
}

