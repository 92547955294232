import { CodeSentiments, ProjectCodingRowTextUIResponse, type ProjectCodingRowUIResponse } from '@/api'

const rowsWidgetDataMock: ProjectCodingRowTextUIResponse[] = [
  {
    ref: 'ro_zevlmqq',
    value: 'Have had a few small issues with the brand, overall would not recommend',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.NEGATIVE,
    was_reviewed: true,
    topics: [
      {
        id: 'cd_1pl5qp9',
        sentiment: CodeSentiments.NEUTRAL,
        sentiment_enabled: true,
        label: 'Smart TV',
        category: 'FEATURES',
        color: 'rgba(54,162,235,0.6)',
        color_from_palette: 'rgba(54,162,235,.6)',
      },
    ],
  },
  {
    ref: 'ro_k8wrxmm',
    value: 'Because Samsung is a highly rated brand, with high quality products.',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,
    topics: [
      {
        id: 'cd_g60kzrg',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Brand',
        category: 'BRAND',
        color: 'rgba(54,162,235,.6)',
        color_from_palette: 'rgba(54,162,235,.6)',
      },
      {
        id: 'cd_187xdd9',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Screen / resolution / picture',
        category: 'QUALITY / TECHNOLOGY',
        color: 'rgba(153,102,255,.6)',
        color_from_palette: 'rgba(54,162,235,.6)',
      },
      {
        id: 'cd_10yl47g',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Audio / sound',
        category: 'QUALITY / TECHNOLOGY',
        color: 'rgba(153,102,255,.6)',
        color_from_palette: 'rgba(54,162,235,.6)',
      },
      {
        id: 'cd_gmyed8g',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Price / cost',
        category: 'PRICE',
        color: 'rgba(121,85,72,.6)',
        color_from_palette: 'rgba(54,162,235,.6)',
      },
    ],
  },
  {
    ref: 'ro_kp5wp4m',
    value:
      // eslint-disable-next-line max-len
      "It's a good TV with a good picture but the apps available are a little limiting and the phone casting doesn't work great",
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.NEUTRAL,
    was_reviewed: true,
    topics: [
      {
        id: 'cd_1ydpkd1',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Overall / liking of TV',
        category: 'OVERALL',
        color: 'rgba(75,192,192,.6)',
        color_from_palette: 'rgba(54,162,235,.6)',
      },
      {
        id: 'cd_9zxkom1',
        sentiment: CodeSentiments.NEGATIVE,
        sentiment_enabled: true,
        label: 'Operability / effective functioning',
        category: 'FUNCTIONAL OPERATION',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(78,78,225,.6)',
      },
      {
        id: 'cd_187xdd9',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Screen / resolution / picture',
        category: 'QUALITY / TECHNOLOGY',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(153,102,255,.6)',
      },
    ],
  },
  {
    ref: 'ro_2v1qpmj',
    value: 'Good quality',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,
    topics: [
      {
        id: 'cd_187xdd9',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Screen / resolution / picture',
        color_from_palette: 'rgba(54,162,235,.6)',
        category: 'QUALITY / TECHNOLOGY',
        color: 'rgba(153,102,255,.6)',
      },
    ],
  },
  {
    ref: 'ro_z451n01',
    value: 'I really like the quality for the price.',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,

    topics: [
      {
        id: 'cd_gmyed8g',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Price / cost',
        category: 'PRICE',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(121,85,72,.6)',
      },
    ],
  },
  {
    ref: 'ro_z3r3mvr',
    value: 'It has a great picture and great sound and all the accessories outlets you meed',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,

    topics: [
      {
        id: 'cd_187xdd9',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Screen / resolution / picture',
        category: 'QUALITY / TECHNOLOGY',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(153,102,255,.6)',
      },
      {
        id: 'cd_10yl47g',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Audio / sound',
        category: 'QUALITY / TECHNOLOGY',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(153,102,255,.6)',
      },
    ],
  },
  {
    ref: 'ro_213x5dg',
    value: 'It had it issues',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.NEGATIVE,
    was_reviewed: true,

    topics: [
      {
        id: 'cd_1doqkp9',
        sentiment: CodeSentiments.NEGATIVE,
        sentiment_enabled: true,
        label: 'Reliability',
        category: 'FUNCTIONAL OPERATION',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(78,78,225,.6)',
      },
    ],
  },
  {
    ref: 'ro_2r1q4me',
    value: 'Because the LG tvs are better because it has different apps',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,

    topics: [],
  },
  {
    ref: 'ro_259r067',
    value: 'It’s a great tv brand, lots of features and it’s a smart tv so I can use it with my smart home products',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,

    topics: [
      {
        id: 'cd_g60kzrg',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Brand',
        category: 'BRAND',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(54,162,235,.6)',
      },
      {
        id: 'cd_goy2749',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Features / options / functions',
        category: 'FEATURES',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(54,162,235,0.6)',
      },
      {
        id: 'cd_1pl5qp9',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Smart TV',
        category: 'FEATURES',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(54,162,235,0.6)',
      },
    ],
  },
  {
    ref: 'ro_2g49pwq',
    value: 'Samsung products last a long time',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,

    topics: [
      {
        id: 'cd_g7vojwg',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Durability / longevity',
        category: 'QUALITY / TECHNOLOGY',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(153,102,255,.6)',
      },
    ],
  },
  {
    ref: 'ro_zm39prd',
    value: 'The Internet conection is to slow',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.NEGATIVE,
    was_reviewed: true,

    topics: [],
  },
  {
    ref: 'ro_2n19pq8',
    value: 'Because it is a wonderful tv',
    sentiment_overall: ProjectCodingRowTextUIResponse.sentiment_overall.POSITIVE,
    was_reviewed: true,

    topics: [
      {
        id: 'cd_1ydpkd1',
        sentiment: CodeSentiments.POSITIVE,
        sentiment_enabled: true,
        label: 'Overall / liking of TV',
        category: 'OVERALL',
        color_from_palette: 'rgba(54,162,235,.6)',
        color: 'rgba(75,192,192,.6)',
      },
    ],
  },
]

export const rowsResponseMock = (): ProjectCodingRowUIResponse[] => {
  return [
    {
      id: 'ie_1',
      upload_index: 1,
      created: '2024-03-06T16:01:52.231Z',
      last_modified: '2024-03-06T16:01:52.231Z',
      text_to_analyze: rowsWidgetDataMock[Math.floor(Math.random() * rowsWidgetDataMock.length)],
      columns: [
        {
          ref: 'co_1',
          value: 'Text',
        },
      ],
    },
    {
      id: 'ie_2',
      upload_index: 2,
      created: '2024-03-06T16:01:52.231Z',
      last_modified: '2024-03-06T16:01:52.231Z',
      text_to_analyze: rowsWidgetDataMock[Math.floor(Math.random() * rowsWidgetDataMock.length)],
      columns: [
        {
          ref: 'co_1',
          value: 'Text',
        },
      ],
    },
    {
      id: 'ie_3',
      upload_index: 3,
      created: '2024-03-06T16:01:52.231Z',
      last_modified: '2024-03-06T16:01:52.231Z',
      text_to_analyze: rowsWidgetDataMock[Math.floor(Math.random() * rowsWidgetDataMock.length)],
      columns: [
        {
          ref: 'co_1',
          value: 'Text',
        },
      ],
    },
  ]
}

// eslint-disable-next-line max-len, vue/max-len
const loremText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum scelerisque mauris a massa accumsan, a euismod erat tincidunt. Quisque finibus felis at odio convallis congue. Curabitur augue lectus, consectetur eu porta non, laoreet sed nulla. Integer quis lobortis dolor. Sed elementum vestibulum nisl a lobortis. Integer consequat, justo tristique varius volutpat, erat ex vehicula erat, eu efficitur tortor leo suscipit turpis. Vivamus vulputate aliquet vehicula. Donec hendrerit sit amet nulla at pretium. Sed mattis scelerisque odio, vel hendrerit velit consequat quis. Proin nec ligula id ex pharetra congue et vel tellus. Nulla tincidunt lacinia fermentum. Duis pulvinar dolor mauris, ac facilisis metus bibendum vel. In hendrerit nisl pretium tempor aliquam. Vivamus sed massa pulvinar, congue nisi vitae, facilisis ipsum. Aenean sit amet mauris tristique, consequat sapien at, tristique justo. Nulla vestibulum lorem sagittis ante mollis.`

export const textResponseMock = () => loremText
