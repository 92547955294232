<template>
  <NResult
    status="404"
    :title="$t('generic_views.not_found_title')"
    :description="$t('generic_views.not_found_message')"
  >
    <template #footer>
      <CRouterLink v-slot="{ navigate }" to="/projects">
        <NButton type="primary" @click="navigate">{{ $t('generic_views.not_found_redirect_me_button') }}</NButton>
      </CRouterLink>
    </template>
  </NResult>
</template>
