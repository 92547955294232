import { FiltersService } from '@/api'
import { keepPreviousData, useMutation, useQuery } from '@tanstack/vue-query'
import { queryClient } from '@/plugins/vue-query'
import type {
  AvailableValuesResponse,
  ContainerAvailableFiltersResponse,
  ContainerFiltersResponse,
  FilterDeleteResponse,
  FilterScope,
  FiltersClearResponse,
  GenericFilterResponse,
} from '@/api'

// All method keys in AccountService
type TFiltersServiceMethodKeys = ClassMethodKeys<typeof FiltersService>
// Parameter types extractor
export type ArgTypes<K extends TFiltersServiceMethodKeys> = ClassMethodParamType<typeof FiltersService, K>

// this factory is used to create query keys for filters
// use it to invalidate filters when they change

/* e.g
this factory key will invalidate all insight element related queries with given insight element id. I will add a comment to explain it;

queryClient.invalidateQueries({
  queryKey: fitersQueryKeyFactory.containerId(FilterContainerPath.INSIGHT_ELEMENTS, insightElementId)
})
*/
export const fitersQueryKeyFactory = {
  filters: ['filters'],
  // use this to invalidate all filters for a modelPath (e.g. 'insight-elements')
  scope: (scope: FilterScope) => [...fitersQueryKeyFactory.filters, scope],
  // use this to invalidate all filters for a model with a containerId (e.g. 'insight-elements' with id '123')
  containerId: (scope: FilterScope, containerId: string) => [...fitersQueryKeyFactory.filters, scope, containerId],
  // use this to invalidate all filters list for a model with a containerId (e.g. 'insight-elements' with id '123')
  list: (scope: FilterScope, containerId: string) => [...fitersQueryKeyFactory.containerId(scope, containerId), 'list'],
  // use this to invalidate all availablefilters for a model with a containerId (e.g. 'insight-elements' with id '123')
  available: (scope: FilterScope, containerId: string) => [
    ...fitersQueryKeyFactory.containerId(scope, containerId),
    'available',
  ],
  projectAvailableFilters: (scope: FilterScope, projectId: string) => [
    ...fitersQueryKeyFactory.scope(scope),
    projectId,
    'available',
  ],
  // use this to invalidate all filter values for a model with a containerId (e.g. 'insight-elements' with id '123')
  values: (scope: FilterScope, containerId: string) => [
    ...fitersQueryKeyFactory.containerId(scope, containerId),
    'values',
  ],
}

/***********/
/* QUERIES */
/***********/
export function useAvailableFiltersListQuery(
  params: ArgTypes<'containerAvailableFiltersList'>,
  options: TCustomUseQueryOptions<ContainerAvailableFiltersResponse> = {}
) {
  return useQuery({
    queryKey: fitersQueryKeyFactory.available(params.scope as FilterScope, params.containerId),
    queryFn: () => FiltersService.containerAvailableFiltersList(params),
    ...options,
  })
}

export function useProjectAvailableFiltersListQuery(
  params: ArgTypes<'containerPathAvailableFiltersList'>,
  options: TCustomUseQueryOptions<ContainerAvailableFiltersResponse> = {}
) {
  return useQuery({
    queryKey: fitersQueryKeyFactory.projectAvailableFilters(params.scope as FilterScope, params.projectId),
    queryFn: () => FiltersService.containerPathAvailableFiltersList(params),
    ...options,
  })
}

export function useFiltersListQuery(
  params: ArgTypes<'containerFiltersList'>,
  options: TCustomUseQueryOptions<ContainerFiltersResponse> = {}
) {
  return useQuery({
    queryKey: fitersQueryKeyFactory.list(params.scope as FilterScope, params.containerId),
    queryFn: () => FiltersService.containerFiltersList(params),
    ...options,
  })
}

export function useFilterValuesQuery(
  scope: FilterScope,
  params: ArgTypes<'projectFiltersValuesGet'>,
  options: TCustomUseQueryOptions<AvailableValuesResponse> = {}
) {
  return useQuery({
    queryKey: fitersQueryKeyFactory.values(scope, params.projectId),
    queryFn: () => FiltersService.projectFiltersValuesGet(params),
    placeholderData: keepPreviousData,
    ...options,
  })
}

/*************/
/* MUTATIONS */
/*************/
type TReportFiltersCreateMutationOptions = TCustomUseMutationOptions<
  GenericFilterResponse,
  ArgTypes<'containerFiltersCreate'>
>
export function useFiltersCreateMutation(options: TReportFiltersCreateMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => FiltersService.containerFiltersCreate(mutationParams),
    onSuccess: async (_, variables) => {
      // TODO: invalidate report data here when filters change
      await queryClient.invalidateQueries({
        queryKey: fitersQueryKeyFactory.containerId(variables.scope as FilterScope, variables.containerId),
      })
    },
    ...options,
  })
}

type TReportFiltersEditMutationOptions = TCustomUseMutationOptions<
  GenericFilterResponse,
  ArgTypes<'containerFiltersEdit'>
>
export function useFiltersEditMutation(scope: FilterScope, options: TReportFiltersEditMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => FiltersService.containerFiltersEdit(mutationParams),
    onSuccess: async (_, variables) => {
      // TODO: invalidate report data here when filters change
      return await queryClient.invalidateQueries({
        queryKey: fitersQueryKeyFactory.containerId(scope, variables.filterId),
      })
    },
    ...options,
  })
}

type TReportFiltersDeleteMutationOptions = TCustomUseMutationOptions<
  FilterDeleteResponse,
  ArgTypes<'containerFiltersDelete'>
>
export function useFiltersDeleteMutation(scope: FilterScope, options: TReportFiltersDeleteMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => FiltersService.containerFiltersDelete(mutationParams),
    onSuccess: async (_, variables) => {
      return await queryClient.invalidateQueries({
        queryKey: fitersQueryKeyFactory.containerId(scope, variables.filterId),
      })
    },
    ...options,
  })
}

type TReportFiltersClearMutationOptions = TCustomUseMutationOptions<
  FiltersClearResponse,
  ArgTypes<'containerFiltersClear'>
>
export function useFiltersClearMutation(options: TReportFiltersClearMutationOptions = {}) {
  return useMutation({
    mutationFn: (mutationParams) => FiltersService.containerFiltersClear(mutationParams),
    onSuccess: async (_, variables) => {
      return await queryClient.invalidateQueries({
        queryKey: fitersQueryKeyFactory.containerId(variables.scope as FilterScope, variables.containerId),
      })
    },
    ...options,
  })
}
