<template>
  <div class="p-16">
    <NResult
      status="404"
      :title="$t('generic_views.report_not_authorized_generic_title')"
      :description="$t('generic_views.report_not_authorized_message')"
    >
      <template #footer>
        <CRouterLink v-slot="{ navigate }" to="/login">
          <NButton type="primary" @click="navigate">{{ $t('generic_views.redirect_to_login_button') }}</NButton>
        </CRouterLink>
      </template>
    </NResult>
  </div>
</template>
