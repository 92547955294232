<template>
  <NSpin v-if="isLoading" :size="16" show />

  <NSelect
    v-else
    :value="modelValue"
    :options="mappedRoleList"
    filterable
    :placeholder="$t('team.select_a_role')"
    :fallback-option="false"
    :render-label="renderLabel"
    :render-tag="renderTag"
    class="w-100%"
    :bordered="false"
    @update:value="(val: number) => $emit('update:value', val)"
  />
</template>

<script setup lang="ts">
import { NTag, type SelectOption, type SelectRenderTag } from 'naive-ui'
import { computed, h } from 'vue'
import { getRoleTranslation } from '@/utils'
import { renderIconButton, renderTooltip } from '@/utils'
import { useOrganizationRoleListQuery } from '@/api/vq/organization'

type TMappedOrgRole = SelectOption & {
  label: string
  description: string
}

defineProps<{
  modelValue?: number
}>()

defineEmits<{
  'update:value': [value: number]
}>()

const { data, isLoading } = useOrganizationRoleListQuery()

const mappedRoleList = computed<TMappedOrgRole[]>(() => {
  if (!data.value) return []

  return data.value.map((role) => {
    const translatedRole = getRoleTranslation(role.name)

    return {
      label: translatedRole.name,
      description: translatedRole.description,
      value: role.id,
      disabled: !role.applicable,
    }
  })
})

const renderLabel = (option: TMappedOrgRole) => {
  return h('div', { class: 'flex w-full' }, [
    renderTooltip(renderIconButton('fa-circle-info'), option.description),
    h('div', { class: 'mx-2' }, option.label),
  ])
}
const renderTag: SelectRenderTag = ({ option }) =>
  h(
    NTag,
    { class: 'b bg-card-color b-color-header-text-color c-header-text-color' },
    { default: () => option.label as string }
  )
</script>

<style scoped lang="scss">
:deep(.n-base-selection) {
  box-shadow: none !important;
  .n-base-selection-tags {
    padding-left: 0 !important;
    background-color: transparent !important;
  }

  .n-base-selection-label {
    background-color: transparent !important;
  }

  .n-base-selection-overlay {
    padding-left: 0 !important;
  }
}

:deep(.n-base-selection-tag-wrapper) {
  .n-tag {
    background-color: var(--card-color);
    border-color: var(--divider-color);
  }
}

:deep(.n-base-suffix__arrow) {
  color: var(--text-color-1) !important;
}
</style>
