import { type InjectionKey, inject, provide } from 'vue'

export const ReportIdKey: InjectionKey<string> = Symbol('reportId')
export const ProjectIdKey: InjectionKey<string> = Symbol('projectId')

export function provideReportId(reportId: string) {
  provide(ReportIdKey, reportId)
}

export function provideProjectId(projectId: string) {
  provide(ProjectIdKey, projectId)
}

export function useReportId(): string {
  const reportId = inject(ReportIdKey)

  if (!reportId) {
    throw new Error('ReportId was not provided')
  }
  return reportId
}

export function useProjectId(): string {
  const projectId = inject(ProjectIdKey)

  if (!projectId) {
    throw new Error('ProjectId was not provided')
  }
  return projectId
}
