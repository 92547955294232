import ph, { PostHog, type Survey } from 'posthog-js'
import { type App, nextTick, ref } from 'vue'
import { useEventBus } from '@/composables/useEventBus'
import type { Router } from 'vue-router'

const DEBUG_FEATURE_FLAG = 'caplena_next_debug'
let posthog: void | PostHog

const shouldPosthogBeInitialized = import.meta.env.CAPP_POSTHOG_PROJECT_API_KEY && !import.meta.env.DEV

function assertPosthogInitialized(posthog: void | PostHog): asserts posthog is PostHog {
  if (!posthog) {
    throw new Error('PostHog is not initialized but it should have been. Check your environment configuration.')
  }
}

export const usePosthog = (app: App, router: Router) => {
  if (!shouldPosthogBeInitialized) return

  posthog = ph.init(import.meta.env.CAPP_POSTHOG_PROJECT_API_KEY, {
    api_host: import.meta.env.CAPP_POSTHOG_INSTANCE_ADDRESS,
    person_profiles: 'identified_only',
    capture_pageview: true,
    disable_session_recording: import.meta.env.MODE === 'development' || import.meta.env.MODE === 'localapi',
    enable_heatmaps: true,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  })

  app.config.globalProperties.$posthog = posthog

  router.afterEach((to, from, failure) => {
    if (!failure) {
      nextTick(() => {
        // create a pageview event for each route change
        if (app.config.globalProperties.$posthog) {
          app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath })
        }
      })
    }
  })
}

// This is a reactive variable that will be updated when the user is identified and feature flags are read, on Dev mode it will always be true
export const canUserSeeDebugFeatures = ref(import.meta.env.DEV)

// Do not use posthog instance directly, create functions that will use it
export const identifyPosthogUser = (
  id: string,
  { organizationId, organizationName }: { organizationId?: number; organizationName?: string } = {}
) => {
  const eventBus = useEventBus()

  return new Promise<void>((resolve) => {
    if (!shouldPosthogBeInitialized) return resolve()
    assertPosthogInitialized(posthog)

    posthog.identify(id, {
      organization_id: organizationId,
      organization_name: organizationName,
    })

    posthog.reloadFeatureFlags()
    eventBus.emit('posthog-user-identified')
    return posthog.onFeatureFlags(() => {
      // feature flags are guaranteed to be available at this point
      if (!posthog) {
        canUserSeeDebugFeatures.value = import.meta.env.DEV

        return resolve()
      }

      canUserSeeDebugFeatures.value = Boolean(posthog.isFeatureEnabled(DEBUG_FEATURE_FLAG))
      return resolve()
    })
  })
}

export const resetPosthogUser = () => {
  if (!shouldPosthogBeInitialized) return
  assertPosthogInitialized(posthog)

  posthog.reset()
  posthog.reloadFeatureFlags()
}

export const getActiveSurveys = (callback: (surveys: Survey[]) => void) => {
  if (!shouldPosthogBeInitialized) return callback([])
  assertPosthogInitialized(posthog)

  return posthog.getActiveMatchingSurveys(callback)
}

export const getActiveFeedbackSurvey = (callback: (survey?: Survey) => void) => {
  if (!shouldPosthogBeInitialized) return callback()
  assertPosthogInitialized(posthog)

  return posthog.getActiveMatchingSurveys((surveys) => {
    const feedbackSurvey = surveys.find((survey) => import.meta.env.CAPP_POSTHOG_FEEDBACK_SURVEY_ID === survey.id)

    return callback(feedbackSurvey)
  })
}

export function itemsIfUserCanSeeDebugFeatures<T>(items: T[]): T[] {
  if (canUserSeeDebugFeatures.value) {
    return items
  }

  return []
}

export function submitFeedbackSurvey(surveyId: string, response: string) {
  if (!shouldPosthogBeInitialized) return
  assertPosthogInitialized(posthog)

  posthog.capture('survey sent', { $survey_id: surveyId, $survey_response: response })
}

export function isFeatureEnabled(featureFlag: string) {
  if (!shouldPosthogBeInitialized) return false
  assertPosthogInitialized(posthog)

  return posthog.isFeatureEnabled(featureFlag)
}

export function renderSurvey(surveyId: string = '01914fe1-fc31-0000-7940-1ae06412c9fa') {
  if (!shouldPosthogBeInitialized) return
  assertPosthogInitialized(posthog)

  posthog.renderSurvey(surveyId, '#posthog-survey')
}
