/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * Defines the kind of the FeatureAvailability as exposed to the client (e.g. FE) code
 */
export enum FeatureAvailabilityKind {
  NO = 'no',
  LIMITED = 'limited',
  UNLIMITED = 'unlimited',
}
