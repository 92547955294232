<template>
  <Container flex class="!rounded-20px report-filters !inline-flex">
    <DropdownFilter
      :filters-config="projectFiltersConfig || []"
      :filters-loading="isLoadingAvailableFilters"
      @update:filters="handleFiltersChange"
    />

    <SelectedFilters v-if="projectFiltersConfig" :filters-config="projectFiltersConfig" />
  </Container>
</template>

<script setup lang="ts">
import { DropdownFilter, SelectedFilters, type TPossibleFilterValue } from '@/components/filters'
import { FilterScope, type GenericFilterRequest } from '@/api'
import {
  type TGenericFilterWithResponse,
  createFilterRequest,
  decodeFilterKey,
} from '@/components/filters/filter-helpers'
import { computed } from 'vue'
import { useProjectAvailableFiltersListQuery } from '@/api/vq/filters'
import { useProjectFiltersConfig } from '@/components/filters/useProjectFiltersConfig'

const props = defineProps<{
  projectId: string
}>()

const { data: availableFilters, isLoading: isLoadingAvailableFilters } = useProjectAvailableFiltersListQuery({
  scope: FilterScope.ALERT_ON_TOPIC_TRENDS,
  projectId: props.projectId,
})

const filters = defineModel<Record<string, GenericFilterRequest>>('filters', { required: true })

const { projectFiltersConfig } = useProjectFiltersConfig({
  appliedFilters: computed(() => Object.values(filters.value).filter((f) => f !== undefined)),
  availableFilters,
  getFilter: (key) => filters.value[key] as TGenericFilterWithResponse, // casting is needed here since it is not possible match request and response in ts level
  isLoadingAvailableFilters,
})

const handleFiltersChange = (attr: keyof typeof filters.value, val: TPossibleFilterValue) => {
  const { kind, columnRef } = decodeFilterKey(attr)

  const filterValue = createFilterRequest(kind, columnRef, val, false)

  filters.value = {
    ...filters.value,
    [attr]: filterValue,
  }
}
</script>
