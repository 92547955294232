/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertIntervalKind } from './AlertIntervalKind';
export type AlertOnPotentiallyNewTopicsResponse = {
  /**
   * Unique identifier for the alert.
   */
  id: string;
  /**
   * Name of this alert.
   */
  name: string;
  /**
   * Timestamp at which the alert was created.
   */
  created: string;
  /**
   * Timestamp at which the alert was last updated.
   */
  last_modified: string;
  interval: AlertIntervalKind;
  kind?: 'alert-on-potentially-new-topics';
  alert_type?: AlertOnPotentiallyNewTopicsResponse.alert_type;
  tta_column: string;
};
export namespace AlertOnPotentiallyNewTopicsResponse {
  export enum alert_type {
    POTENTIALLY_NEW_TOPICS = 'potentially-new-topics',
  }
}

