<template>
  <RouterLink v-bind="$props" custom v-slot="{ href, navigate, isActive }">
    <a v-bind="$attrs" :href="href" @click="(e) => customNavigate(e, navigate)">
      <slot :is-active="isActive" />
    </a>
  </RouterLink>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router'

defineOptions({
  inheritAttrs: false,
})

defineProps(
  // @ts-expect-error https://router.vuejs.org/guide/advanced/extending-router-link.html
  RouterLink.props
)

const customNavigate = (e: MouseEvent, navigate: () => void) => {
  if (e.ctrlKey || e.metaKey) {
    // Allow browser to handle modifier key clicks (open in new tab)
    return
  }

  // Prevent default navigation and handle via Vue Router
  e.preventDefault()
  navigate()
}
</script>
