<template>
  <div class="settings-view-container">
    <AppBarBreadcrumb>{{ $t('settings.pagination_name') }}</AppBarBreadcrumb>

    <TabbedView :tab-items="tabItems" />
  </div>
</template>

<script setup lang="ts">
import AppBarBreadcrumb from '@/components/common/AppBarBreadcrumb.vue'
import TabbedView from '@/components/TabbedView.vue'
import { computed, watchEffect } from 'vue'
import { useAuthStore } from '@/store'
import { useTranslate } from '@tolgee/vue'
import type { TTabItem } from '@/components/TabbedView.vue'

const { t } = useTranslate()
const auth = useAuthStore()

const tabItems = computed<Array<TTabItem>>(() => [
  {
    label: t.value('settings.account.tab_name'),
    icon: 'far fa-user',
    routeName: 'settings_account',
  },
  {
    label: t.value('settings.theme.tab_name'),
    icon: 'far fa-paintbrush',
    routeName: 'settings_theme',
  },
  {
    label: t.value('settings.api.tab_name'),
    icon: 'far fa-circle-nodes',
    routeName: 'settings_api',
  },
  {
    label: t.value('settings.security.tab_name'),
    icon: 'far fa-shield-halved',
    routeName: 'settings_security',
  },
  {
    label: t.value('settings.integrations.tab_name'),
    icon: 'far fa-plug',
    routeName: 'settings_integrations',
  },
])

watchEffect(() => {
  if (auth.hasOrganization) {
    tabItems.value.splice(1, 0, {
      label: t.value('settings.organization.tab_name'),
      icon: 'far fa-building',
      routeName: 'settings_organization',
    })
  }
})
</script>
