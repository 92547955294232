/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum AlertOnTopicTrendsMetricKind {
  EITHER_METRIC = 'either-metric',
  TOTAL_COUNT_METRIC = 'total-count-metric',
  SENTIMENT_SHARE_METRIC = 'sentiment-share-metric',
}
