/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum DateDynamicRangeKind {
  ALL_TIME = 'all-time',
  LAST_YEAR = 'last-year',
  THIS_YEAR = 'this-year',
  LAST_QUARTER = 'last-quarter',
  THIS_QUARTER = 'this-quarter',
  LAST_MONTH = 'last-month',
  LAST_30_DAYS = 'last-30-days',
  LAST_3_MONTHS = 'last-3-months',
  LAST_12_MONTHS = 'last-12-months',
  THIS_MONTH = 'this-month',
}
