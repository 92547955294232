/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum AlertOnTopicTrendsPublicType {
  AUTO_DIGEST = 'auto-digest',
  CHANGES = 'changes',
  OCCURRENCES = 'occurrences',
}
