<template>
  <NSpin :show="loading || loadingItems">
    <!-- Input -->
    <slot name="input" :placeholder="{ inputPlaceholder, searchKey }">
      <template v-if="searchable && items.length">
        <SearchAndSelectButtons
          v-model:search-value="searchKey"
          :has-filtered-items="filteredItems.length > 0"
          :multi-select="multiSelect"
          :class="searchClass ? searchClass : 'px-3 py-2'"
          @select-all="onSelectClick"
          @clear-all="onClearClick"
        />

        <NDivider class="!mb-0 !mt-0" />
      </template>
    </slot>

    <!-- List -->
    <slot name="listContent" :items="filteredItems">
      <NScrollbar v-if="filteredItems.length" class="mt-1" :style="{ height: `${scrollHeight}px`, maxWidth }">
        <NSpace vertical :class="$attrs.class ? $attrs.class : 'pa-2'">
          <div class="flex items-center gap-2">
            <label v-if="label" class="font-500 c-gray-600 text-xs">{{ label }}</label>
            <NTooltip v-if="description">
              <template #trigger>
                <FaIcon icon="fa-info-circle" size="xs" />
              </template>
              <div class="c-tertiary-text-color text-xs">
                {{ description }}
              </div>
            </NTooltip>
          </div>
          <div
            v-for="(item, i) in filteredItems"
            :key="i"
            class="flex items-center justify-between"
            @mouseenter="hoveredIndex = i"
            @mouseleave="hoveredIndex = undefined"
          >
            <NCheckbox
              :checked="isChecked(item)"
              class="flex w-full items-center"
              @update:checked="(checked: boolean) => onCheckboxUpdate(item, checked)"
            >
              <slot name="checkboxContent" :item="item">
                <div class="font-500">
                  {{ item }}
                </div>
              </slot>
            </NCheckbox>
            <NButton v-if="i === hoveredIndex" text size="tiny" @click.stop.prevent="onOnlySelect(item)">Only</NButton>
          </div>
        </NSpace>
      </NScrollbar>

      <!-- loading -->
      <div v-else-if="loading" class="pa-2">
        <NSkeleton text :repeat="4" />
      </div>

      <!-- empty -->
      <div v-else-if="!filteredItems.length && !loadingItems" class="text-subheader px-2 py-4 text-center text-sm">
        <slot name="no-data">
          {{ $t('common.no_data') }}
        </slot>
      </div>

      <div v-else :style="{ height: `${searchable ? scrollHeight + 85 : scrollHeight}px` }" />

      <div v-if="hasMoreValues" class="c-tertiary-text-color p-3 text-xs">
        {{ $t('common.has_more_values_than_displayed') }}
      </div>
    </slot>
  </NSpin>
</template>

<script setup lang="ts">
import SearchAndSelectButtons from './SearchAndSelectButtons.vue'
import { computed, onMounted, ref, watch } from 'vue'
import { uniq } from 'lodash-es'
import { watchDebounced } from '@vueuse/core'

type TCheckboxListValue = string | number | Array<string | number> | boolean
type TCheckboxListItem = string | number | Record<string, unknown>

interface ICheckboxListProps {
  value?: TCheckboxListValue
  lazy?: boolean
  fetchValues?: (
    searchValue?: string
  ) => Promise<{ values: Array<string | number | Record<string, unknown>> | undefined; hasMoreValues: boolean }>
  items: Array<string | number | Record<string, unknown>>
  // when used object arrays as items, key value to map values from
  valueKey?: string
  multiSelect?: boolean
  searchable?: boolean
  searchItemKey?: string
  searchClass?: string
  inputPlaceholder?: string
  loading?: boolean
  scrollHeight?: number
  maxWidth?: string
  label?: string
  sortSelected?: boolean
  description?: string
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<ICheckboxListProps>(), {
  items: () => [],
  valueKey: 'value',
  multiSelect: true,
  searchable: true,
  searchClass: '',
  loading: false,
  scrollHeight: 300,
  sortSelected: false,
})

const emit = defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'update:value': [value: any]
}>()

const searchKey = ref('')
const hoveredIndex = ref()
const items = ref(props.items)
const loadingItems = ref(false)
const hasMoreValues = ref(false)

watch(
  () => props.items,
  () => {
    if (!props.lazy) items.value = props.items
  },
  { immediate: true }
)

onMounted(() => {
  if (props.lazy && props.fetchValues) {
    loadingItems.value = true
    props.fetchValues().then((data) => {
      if (!data) return

      items.value = data.values || []
      hasMoreValues.value = data.hasMoreValues
      loadingItems.value = false
    })
  }
})

watchDebounced(
  searchKey,
  (value) => {
    if (props.fetchValues) {
      props.fetchValues(value)
    }
  },
  { debounce: 500 }
)

const filteredItems = computed(() => {
  if (!items.value.length) return []

  let clonedItems = [...items.value]

  if (searchKey.value !== '') {
    clonedItems = clonedItems.filter((item) => {
      // string array
      if (typeof item === 'string' || typeof item === 'number')
        return String(item).toLowerCase().includes(searchKey.value.toLowerCase())

      // object array
      if (!props.searchItemKey) {
        // eslint-disable-next-line
            let message = `searchItemKey prop is missing. When item is an object array, you need to define searchItemKey.\nAvailable keys: ${Object.keys(item).join(' | ')}`

        throw Error(message)
      }

      return String(item[props.searchItemKey]).toLowerCase().includes(searchKey.value.toLowerCase())
    })
  }

  if (props.sortSelected) {
    const unselectedItems = clonedItems.filter((item) => !isChecked(item))
    const valuesArray = Array.isArray(props.value) ? props.value : [props.value]
    const selectedItems = valuesArray
      .map((value) => clonedItems.find((item) => parseItemValue(item) === value))
      .filter((v) => v !== undefined)

    return [...selectedItems, ...unselectedItems]
  }

  return clonedItems
})

const validateValueKey = (item: object) => {
  if (!props.valueKey) {
    // eslint-disable-next-line
        throw Error(`You are using object array in items and value-key is missing!\nAvailable keys: ${Object.keys(item).join(' | ')}`)
  }
  if (props.valueKey && !(props.valueKey in item)) {
    // eslint-disable-next-line
        throw Error(`value-key: '${props.valueKey}' is not available in the object!\nAvailable keys: ${Object.keys(item).join(' | ')}`
    )
  }
  // valid
  return true
}

const parseItemValue = (item: TCheckboxListItem) => {
  return typeof item === 'object' && validateValueKey(item) ? item[props.valueKey as string] : (item as string | number)
}

const isChecked = (item: TCheckboxListItem) => {
  // props value is empty, no checked items
  if (!props.value) return false

  const itemValue = parseItemValue(item)

  return Array.isArray(props.value) ? props.value.includes(String(itemValue)) : itemValue === props.value
}

const onCheckboxUpdate = (item: TCheckboxListItem, checked: boolean) => {
  const currentValue = !props.value ? [] : (props.value as Array<string | number>)
  const itemValue = parseItemValue(item)

  if (props.multiSelect) {
    emit('update:value', checked ? [...currentValue, itemValue] : currentValue.filter((val) => itemValue !== val))
    return
  }
  emit('update:value', checked ? itemValue : undefined)
}

const onOnlySelect = (item: TCheckboxListItem) => {
  const itemValue = parseItemValue(item)

  emit('update:value', [itemValue])
}

const onSelectClick = () => {
  const currentValue = !props.value ? [] : (props.value as Array<string | number>)
  const filteredValues = filteredItems.value.map((item) => parseItemValue(item))

  emit('update:value', uniq([...currentValue, ...filteredValues]))
}

const onClearClick = () => {
  const currentValue = !props.value ? [] : (props.value as Array<string | number>)
  const filteredValues = filteredItems.value.map((item) => parseItemValue(item))

  emit(
    'update:value',
    currentValue.filter((val) => !filteredValues.includes(val))
  )
}
</script>

<style lang="scss" scoped></style>
