/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AvailableValuesFilterRequest } from '../models/AvailableValuesFilterRequest';
import type { AvailableValuesResponse } from '../models/AvailableValuesResponse';
import type { ContainerAvailableFiltersResponse } from '../models/ContainerAvailableFiltersResponse';
import type { ContainerFiltersResponse } from '../models/ContainerFiltersResponse';
import type { FilterDeleteResponse } from '../models/FilterDeleteResponse';
import type { FilterQueryStrResponse } from '../models/FilterQueryStrResponse';
import type { FiltersClearResponse } from '../models/FiltersClearResponse';
import type { GenericFilterRequest } from '../models/GenericFilterRequest';
import type { GenericFilterResponse } from '../models/GenericFilterResponse';
import type { QueryStrFilterRequest } from '../models/QueryStrFilterRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FiltersService {
  /**
   * Container Filters List
   * @returns ContainerFiltersResponse OK
   * @throws ApiError
   */
  public static containerFiltersList({
    scope,
    containerId,
  }: {
    /**
     * An enumeration.
     */
    scope: 'reports' | 'insight-elements' | 'report-views' | 'segments' | 'project-data' | 'topic-assignments' | 'alert-on-topic-trends',
    containerId: string,
  }): CancelablePromise<ContainerFiltersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/filtering/{scope}/{container_id}/filters',
      path: {
        'scope': scope,
        'container_id': containerId,
      },
    });
  }
  /**
   * Container Filters Create
   * @returns GenericFilterResponse OK
   * @throws ApiError
   */
  public static containerFiltersCreate({
    scope,
    containerId,
    requestBody,
  }: {
    /**
     * An enumeration.
     */
    scope: 'reports' | 'insight-elements' | 'report-views' | 'segments' | 'project-data' | 'topic-assignments' | 'alert-on-topic-trends',
    containerId: string,
    requestBody: GenericFilterRequest,
  }): CancelablePromise<GenericFilterResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/filtering/{scope}/{container_id}/filters',
      path: {
        'scope': scope,
        'container_id': containerId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Container Filters Clear
   * @returns FiltersClearResponse OK
   * @throws ApiError
   */
  public static containerFiltersClear({
    scope,
    containerId,
  }: {
    /**
     * An enumeration.
     */
    scope: 'reports' | 'insight-elements' | 'report-views' | 'segments' | 'project-data' | 'topic-assignments' | 'alert-on-topic-trends',
    containerId: string,
  }): CancelablePromise<FiltersClearResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/filtering/{scope}/{container_id}/filters',
      path: {
        'scope': scope,
        'container_id': containerId,
      },
    });
  }
  /**
   * List available filters for columns of a specific container
   * @returns ContainerAvailableFiltersResponse OK
   * @throws ApiError
   */
  public static containerAvailableFiltersList({
    scope,
    containerId,
  }: {
    /**
     * An enumeration.
     */
    scope: 'reports' | 'insight-elements' | 'report-views' | 'segments' | 'project-data' | 'topic-assignments' | 'alert-on-topic-trends',
    containerId: string,
  }): CancelablePromise<ContainerAvailableFiltersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/filtering/{scope}/{container_id}/available-filters',
      path: {
        'scope': scope,
        'container_id': containerId,
      },
    });
  }
  /**
   * List available filters for project columns for specific container type
   * @returns ContainerAvailableFiltersResponse OK
   * @throws ApiError
   */
  public static containerPathAvailableFiltersList({
    scope,
    projectId,
  }: {
    /**
     * An enumeration.
     */
    scope: 'reports' | 'insight-elements' | 'report-views' | 'segments' | 'project-data' | 'topic-assignments' | 'alert-on-topic-trends',
    projectId: string,
  }): CancelablePromise<ContainerAvailableFiltersResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/filtering/projects/{project_id}/{scope}/available-filters',
      path: {
        'scope': scope,
        'project_id': projectId,
      },
    });
  }
  /**
   * List values available to be used with a specific filter
   * This endpoint is useful to retrieve available values for a certain FilterKind applied on a
   * certain Column considering any already existing filters.
   *
   * Already existing filters can be specficied via the `filters` attribute
   * @returns AvailableValuesResponse OK
   * @throws ApiError
   */
  public static projectFiltersValuesGet({
    projectId,
    requestBody,
  }: {
    projectId: string,
    requestBody: AvailableValuesFilterRequest,
  }): CancelablePromise<AvailableValuesResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/filtering/projects/{project_id}/filters/values/_get',
      path: {
        'project_id': projectId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Get query string from list of filter requests
   * This endpoint is used to get query string from filters provided.
   * Query string can be used then for requests using GET HTTP method.
   * @returns FilterQueryStrResponse OK
   * @throws ApiError
   */
  public static filterQueryStrGet({
    requestBody,
  }: {
    requestBody: QueryStrFilterRequest,
  }): CancelablePromise<FilterQueryStrResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/filtering/filters/query-str/_get',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Container Filters Full Update
   * @returns GenericFilterResponse OK
   * @throws ApiError
   */
  public static containerFiltersEdit({
    filterId,
    requestBody,
  }: {
    filterId: string,
    requestBody: GenericFilterRequest,
  }): CancelablePromise<GenericFilterResponse> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/filtering/filters/{filter_id}',
      path: {
        'filter_id': filterId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Container Filters Delete
   * @returns FilterDeleteResponse OK
   * @throws ApiError
   */
  public static containerFiltersDelete({
    filterId,
  }: {
    filterId: string,
  }): CancelablePromise<FilterDeleteResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/filtering/filters/{filter_id}',
      path: {
        'filter_id': filterId,
      },
    });
  }
}
