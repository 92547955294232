import { AuxiliaryFieldUIResponse, FilterKind, FilterScope, type GenericFilterRequest } from '@/api'
import { type ComputedRef, type MaybeRef, computed, ref, toValue, watch } from 'vue'
import { EFilterType, type TFilterConfig, type TFilterValue, type TPossibleFilterValue } from '@/components/filters'
import {
  type TGenericFilterWithResponse,
  createFilterRequest,
  decodeFilterKey,
  encodeFilterKey,
  getFilterValue,
  mapFilterType,
} from '@/components/filters/filter-helpers'
import { decodeFilterQuery, encodeStr } from '@/utils'
import { mapKeys, mapValues } from 'lodash-es'
import { useProjectAvailableFiltersListQuery } from '@/api/vq/filters'
import { useProjectFiltersConfig } from '@/components/filters/useProjectFiltersConfig'
import { useUrlSearchParams } from '@vueuse/core'

const decodeColumnsParam = (columnsParam: string | undefined, questions: ComputedRef<AuxiliaryFieldUIResponse[]>) => {
  const filterTypes: Record<string, TFilterValue> = {}

  if (!columnsParam) return filterTypes

  columnsParam.split(';').forEach((filter) => {
    const singleFilter = filter.split(':')
    const matchResult = singleFilter[0].match(/\w+\[(.+?)\]/)
    const auxiliaryType = matchResult?.[1] ?? ''
    const columnRef = singleFilter[0].split('[')[0]
    const column = questions.value?.find((col) => col.ref === columnRef)

    if (auxiliaryType === 'text') {
      // select filter vs single text filter
      filterTypes[columnRef] = column?.metadata.has_few
        ? filter
            .replaceAll(`${columnRef}[text]:`, '')
            .split(',')
            .map((val) => decodeFilterQuery(val)[0].value)
        : decodeFilterQuery(singleFilter[1])[0].value
    }

    if (auxiliaryType === 'numerical') {
      // range
      const min = columnsParam?.split(`${columnRef}[numerical].gte:`)[1].split(';')[0]
      const max = columnsParam?.split(`${columnRef}[numerical].lte:`)[1].split(';')[0]

      filterTypes[columnRef] = [Number(min), Number(max)]
    }

    if (auxiliaryType === 'date') {
      const dateMinString = columnsParam?.split(`${columnRef}[date].gte:`)[1].split(';')[0].replace(/\\/g, '')
      const dateMaxString = columnsParam?.split(`${columnRef}[date].lte:`)[1].split(';')[0].replace(/\\/g, '')

      if (dateMinString && dateMaxString && Date.parse(dateMaxString) && Date.parse(dateMinString)) {
        const dateMin = new Date(dateMinString).getTime()
        const dateMax = new Date(dateMaxString).getTime()

        filterTypes[columnRef] = [dateMin, dateMax]
      }
    }
  })

  return filterTypes
}

export const useProjectFiltersWithURLSearchParams = (
  auxiliaryQuestions: ComputedRef<AuxiliaryFieldUIResponse[]>,
  projectId: string,
  scope: FilterScope,
  extraFilters?: MaybeRef<TFilterConfig[]>
) => {
  const URLParams = useUrlSearchParams<Record<string, string>>()
  // reactive state to hold auxiliaryFilters (dynamic) filter values
  const filters = ref<Record<string, GenericFilterRequest | undefined>>({
    ...auxiliaryQuestions.value.reduce((obj, col) => ({ ...obj, [col.ref]: undefined }), {}),
  })

  const filterValues = computed<Record<string, TPossibleFilterValue>>(() => {
    return mapValues(filters.value, (val) => {
      if (!val) return undefined

      return getFilterValue(val as TGenericFilterWithResponse)
    })
  })

  const {
    data: availableFilters,
    isLoading: isLoadingAvailableFilters,
    status: availableFiltersStatus,
  } = useProjectAvailableFiltersListQuery({
    scope,
    projectId,
  })

  const { projectFiltersConfig } = useProjectFiltersConfig({
    appliedFilters: computed(() => Object.values(filters.value).filter((f) => f !== undefined)),
    availableFilters,
    getFilter: (key) => filters.value[key] as TGenericFilterWithResponse, // casting is needed here since it is not possible match request and response in ts level
    isLoadingAvailableFilters,
    showOnlyAuxiliaryFilters: true,
  })

  const filtersConfig = computed(() => [...(toValue(extraFilters) ?? []), ...(projectFiltersConfig.value ?? [])])

  watch(
    availableFiltersStatus,
    (status) => {
      if (status === 'success') {
        const columnFilters = decodeColumnsParam(URLParams.columns, auxiliaryQuestions)

        Object.entries(columnFilters).forEach(([key, value]) => {
          const filterType = availableFilters.value?.filters[key][0] || FilterKind.TEXT_VALUE_EQUALS

          filters.value[encodeFilterKey(filterType, key)] = createFilterRequest(filterType, key, value)
        })
      }
    },
    { immediate: true }
  )

  /**
   * this method is called each time there is a change in the auxiliary/dynamic filters
   * takes filter values and generates query string for the API
   **/
  const generateColumnsQueryFromFilters = () => {
    const columnFilters: Array<string> = []

    for (const [key, val] of Object.entries(filterValues.value)) {
      const { columnRef, kind } = decodeFilterKey(key)
      const column = auxiliaryQuestions.value.find((c) => c.ref === columnRef)

      if (!column || !val || (Array.isArray(val) && !val.length)) continue

      const { type: filterType } = mapFilterType(kind)

      if (kind === FilterKind.DATE_DYNAMIC_RANGE && typeof val === 'string') {
        return `${column.ref}[date].range:${val.replace('-', '_')}`
      }

      if (filterType === EFilterType.Checkbox) {
        // in most cases, value here will be a string array
        // but to make sure check the type and parse regardingly
        const parsedValue = Array.isArray(val)
          ? val.map((v) => `${column.ref}[text]:${encodeStr(String(v))}`).join(',')
          : `${column.ref}[text]:${encodeStr(String(val))}`

        columnFilters.push(parsedValue)
      }

      if (filterType === EFilterType.Date) {
        const [start, end] = val as number[]
        const startDate = new Date(start).toISOString().replace(/:/g, '\\:')
        const endDate = new Date(end).toISOString().replace(/:/g, '\\:')

        columnFilters.push(`${column.ref}[date].gte:${startDate};${column.ref}[date].lte:${endDate}`)
      }

      if (filterType === EFilterType.Range) {
        const [start, end] = val as number[]

        columnFilters.push(`${column.ref}[numerical].gte:${start};${column.ref}[numerical].lte:${end}`)
      }

      if (filterType === EFilterType.SearchInput) {
        const value = encodeStr(val as string)

        // TO DO: not sure if we need `column.type` here instead of text? figure out
        columnFilters.push(`${column.ref}[text].contains.i:${value}`)
      }
    }

    return columnFilters.length ? columnFilters.join(';') : undefined
  }

  const reset = () => {
    // reset each filter data
    mapKeys(filters.value, (val, type) => (filters.value[type] = undefined))
  }

  return {
    filters,
    filtersConfig,
    generateColumnsQueryFromFilters,
    reset,
  }
}
