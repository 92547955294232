import {
  AvailableWidgetId,
  ColumnKind,
  SegmentKind,
  type SegmentedDataSeriesResponse,
  type SegmentedDataSeriesWithOverallResponse,
  StarRatingInsightElementResponse,
  type StarRatingWoTimeInsightElementResponse,
  WidgetKind,
} from '@/api'

export const starRatingWOTimeInsightMock: StarRatingWoTimeInsightElementResponse = {
  id: 'ie_1',
  kind: 'StarRatingWoTimeInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  filters: [],
  available_filters: {},
  order_id: 1,
  star_breakdown: {
    id: 'wg_1',
    kind: WidgetKind.RATING_BREAKDOWN,
    name: 'Star Rating Without Time',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.STAR_BREAKDOWN,
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
  star_segmented_bar: {
    id: 'wg_4',
    kind: WidgetKind.RATING_SEGMENTED_BAR,
    name: 'Star Rating Segmented Bar',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.STAR_SEGMENTED_BAR,
    widget_config: {
      id: 'wg_4',
      widget_id: 'wg_4',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
}

export const starRatingInsightMock: StarRatingInsightElementResponse = {
  id: 'ie_1',
  kind: 'StarRatingInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  filters: [],
  available_filters: {},
  order_id: 1,
  star_breakdown: {
    id: 'wg_1',
    kind: WidgetKind.RATING_BREAKDOWN,
    name: 'Star Rating Breakdown',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.STAR_BREAKDOWN,
    widget_config: {
      id: 'wg_1',
      widget_id: 'wg_1',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
  star_over_time_line: {
    id: 'wg_3',
    kind: WidgetKind.RATING_OVER_TIME_LINE,
    name: 'Star Rating Time Bar',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.STAR_OVER_TIME_LINE,
    widget_config: {
      id: 'wg_3',
      widget_id: 'wg_3',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
  star_segmented_bar: {
    id: 'wg_4',
    kind: WidgetKind.RATING_SEGMENTED_BAR,
    name: 'Star Rating Segmented Bar',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.STAR_SEGMENTED_BAR,
    widget_config: {
      id: 'wg_4',
      widget_id: 'wg_4',
      bucket_configs: [],
    },
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
  },
  visible_left: StarRatingInsightElementResponse.visible_left.STAR_BREAKDOWN,
  visible_right: StarRatingInsightElementResponse.visible_right.STAR_OVER_TIME_LINE,
}

export const scoreWithScaleMock: SegmentedDataSeriesWithOverallResponse = {
  kind: 'segmented-data-series-with-overall',
  label: 'NPS Score',
  value: 0.6,
  tick_labels: ['1', '2', '3', '4', '5'],
  values: [
    {
      segment_id: 'sg_1',
      segment_kind: SegmentKind.OVERALL,
      values: [
        {
          score: 20,
          row_count: 30.0,
          row_count_relative: 0.3,
        },
        {
          score: 50,
          row_count: 50.0,
          row_count_relative: 0.5,
        },
        {
          score: 60,
          row_count: 100.0,
          row_count_relative: 0.9,
        },
        {
          score: 50,
          row_count: 50.0,
          row_count_relative: 0.5,
        },
        {
          score: 50,
          row_count: 50.0,
          row_count_relative: 0.5,
        },
      ],
    },
  ],
}

export const timeSeriesMock: SegmentedDataSeriesResponse = {
  kind: 'segmented-data-series',
  tick_labels: [
    'Q1 2021',
    'Q2 2021',
    'Q3 2021',
    'Q4 2021',
    'Q1 2022',
    'Q2 2022',
    'Q3 2022',
    'Q4 2022',
    'Q1 2023',
    'Now',
  ],
  values: [
    {
      segment_id: 'sg_er50o52',
      segment_kind: SegmentKind.OVERALL,
      // values: [-25.0, -15.0, 17.0, 11.0, 22.0, 31.0, 1.0, -13.0, -15.0, 1.0, 8.0],
      values: [
        {
          score: 0.4,
          row_count: 25.0,
          row_count_relative: 0.25,
        },
        {
          score: 0.4,
          row_count: 15.0,
          row_count_relative: 0.15,
        },
        {
          score: 0.3,
          row_count: 11.0,
          row_count_relative: 0.11,
        },
        {
          score: 0.4,
          row_count: 22.0,
          row_count_relative: 0.22,
        },
        {
          score: 0.45,
          row_count: 31.0,
          row_count_relative: 0.31,
        },
        {
          score: 0.55,
          row_count: 1.0,
          row_count_relative: 0.1,
        },
        {
          score: 0.5,
          row_count: 13.0,
          row_count_relative: 0.13,
        },
        {
          score: 0.6,
          row_count: 15.0,
          row_count_relative: 0.15,
        },
        {
          score: 0.5,
          row_count: 1.0,
          row_count_relative: 0.1,
        },
        {
          score: 0.6,
          row_count: 8.0,
          row_count_relative: 0.8,
        },
      ],
    },
  ],
}
