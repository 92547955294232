/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterKind } from './FilterKind';
import type { GenericFilterResponse } from './GenericFilterResponse';
import type { SentimentGaugeChartWidgetResponse } from './SentimentGaugeChartWidgetResponse';
import type { SentimentSegmentedBarChartWidgetResponse } from './SentimentSegmentedBarChartWidgetResponse';
import type { TextWidgetResponse } from './TextWidgetResponse';
import type { TtaColumnRowsWidgetResponse } from './TtaColumnRowsWidgetResponse';
export type OverallSentimentWoTimeInsightElementResponse = {
  id: string;
  kind?: 'OverallSentimentWoTimeInsight';
  section_id: string;
  order_id: number;
  created: string;
  last_modified: string;
  filters: Array<GenericFilterResponse>;
  available_filters: Record<string, Array<FilterKind>>;
  sentiment_score_gauge: SentimentGaugeChartWidgetResponse;
  sentiment_score_segmented_bar_chart: SentimentSegmentedBarChartWidgetResponse;
  positive_sentiment_rows: TtaColumnRowsWidgetResponse;
  positive_sentiment_summary: TextWidgetResponse;
  negative_sentiment_rows: TtaColumnRowsWidgetResponse;
  negative_sentiment_summary: TextWidgetResponse;
  visible_left: OverallSentimentWoTimeInsightElementResponse.visible_left;
  visible_center: OverallSentimentWoTimeInsightElementResponse.visible_center;
  visible_right: OverallSentimentWoTimeInsightElementResponse.visible_right;
};
export namespace OverallSentimentWoTimeInsightElementResponse {
  export enum visible_left {
    SENTIMENT_SCORE_GAUGE = 'sentiment_score_gauge',
    SENTIMENT_SCORE_SEGMENTED_BAR_CHART = 'sentiment_score_segmented_bar_chart',
  }
  export enum visible_center {
    POSITIVE_SENTIMENT_ROWS = 'positive_sentiment_rows',
    POSITIVE_SENTIMENT_SUMMARY = 'positive_sentiment_summary',
  }
  export enum visible_right {
    NEGATIVE_SENTIMENT_ROWS = 'negative_sentiment_rows',
    NEGATIVE_SENTIMENT_SUMMARY = 'negative_sentiment_summary',
  }
}

