<template>
  <div>
    <NInput
      v-model:value="searchValue"
      :placeholder="inputPlaceholder ? inputPlaceholder : $t('common.search')"
      clearable
      @keydown.esc="searchValue = ''"
    >
      <template #prefix>
        <NIcon>
          <FaIcon icon="fa-search" size="xs" />
        </NIcon>
      </template>
    </NInput>
    <div v-if="hasFilteredItems && multiSelect" class="mt-2 flex gap-2">
      <NButton size="tiny" @click="$emit('selectAll')">
        {{ searchValue.length ? $t('common.select_searched') : $t('common.select_all') }}
      </NButton>
      <NButton size="tiny" @click="$emit('clearAll')">
        {{ searchValue.length ? $t('common.clear_searched') : $t('common.clear_all') }}
      </NButton>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  inputPlaceholder?: string
  hasFilteredItems: boolean
  multiSelect: boolean
}>()

defineEmits<{
  (e: 'selectAll'): void
  (e: 'clearAll'): void
}>()

const searchValue = defineModel<string>('searchValue', { required: true })
</script>
