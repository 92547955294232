/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum FilterKind {
  TTA_CATEGORY_TOPIC_EQUALS = 'tta-category-topic-equals',
  TTA_CATEGORY_TOPIC_SENTIMENT_EQUALS = 'tta-category-topic-sentiment-equals',
  TTA_CATEGORY_EQUALS = 'tta-category-equals',
  TTA_CATEGORY_SENTIMENT_EQUALS = 'tta-category-sentiment-equals',
  TTA_SENTIMENT_EQUALS = 'tta-sentiment-equals',
  TTA_IS_REVIEWED = 'tta-is-reviewed',
  TTA_IS_EMPTY = 'tta-is-empty',
  TTA_VALUE_EQUALS = 'tta-value-equals',
  TTA_IS_HIGHLIGHTED = 'tta-is-highlighted',
  TEXT_VALUE_EQUALS = 'text-value-equals',
  TEXT_VALUE_CONTAINS = 'text-value-contains',
  NUMERICAL_VALUE_BETWEEN = 'numerical-value-between',
  DATE_VALUE_BETWEEN = 'date-value-between',
  DATE_DYNAMIC_RANGE = 'date-dynamic-range',
  BOOL_VALUE_EQUALS = 'bool-value-equals',
}
