<template>
  <NLayoutHeader class="app-bar-layout">
    <!-- NOTE: Teleport location for Breadcrumb -->
    <div id="breadcrumb" />

    <!-- Theme / Notifications / User -->
    <NCard class="app-bar-actions" :bordered="false" content-style="padding: 8px 0;">
      <div class="flex gap-2">
        <NTooltip trigger="hover">
          <template #trigger>
            <a :href="legacyRedirectUrl()" rel="noopener noreferrer">
              <NButton icon circle quaternary type="info">
                <FaIcon :icon="'far fa-arrow-left-to-arc'" size="lg" />
              </NButton>
            </a>
          </template>
          {{ $t('navigation.switch_to_legacy') }}
        </NTooltip>

        <NButton
          v-if="inLocalDevMode"
          type="info"
          quaternary
          icon
          circle
          @click="themeStore.setThemeWithName(themeStore.current === 'dark' ? 'light' : 'dark')"
        >
          <FaIcon :icon="themeStore.current === 'dark' ? 'fa-sun' : 'far fa-moon'" size="lg" />
        </NButton>

        <!-- TODO: Enable later once it is functional -->
        <!-- Search -->
        <SearchInput v-if="canUserSeeDebugFeatures" collapsable circle />

        <!-- Notifications button should not be removed from DOM as "
          defaultTextColor" should be used at least once in template for theming to work properly -->
        <!-- Notifications -->
        <NButton v-show="canUserSeeDebugFeatures" quaternary icon circle>
          <NBadge>
            <FaIcon icon="far fa-bell" size="lg" :color="defaultTextColor" />
          </NBadge>
        </NButton>

        <!-- Help Dropdown -->
        <Dropdown v-model="helpDropdownVisible">
          <template #activator>
            <NButton quaternary icon circle>
              <FaIcon icon="far fa-question-circle" size="lg" :color="defaultTextColor" />
            </NButton>
          </template>

          <a
            href="https://caplena.com/docs/knowledge-base/branches/v3/wktwlwivyiz40-how-can-we-help-you"
            target="_blank"
            rel="noopener noreferrer"
          >
            <NButton icon block quaternary class="justify-start px-2">
              <FaIcon icon="far fa-book" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.documentation') }}
            </NButton>
          </a>

          <NButton
            v-if="feedbackSurvey"
            icon
            block
            quaternary
            class="survey-button justify-start px-2"
            @click="openSurvey"
          >
            <FaIcon icon="far fa-comment-dots" class="mr-2" :color="defaultTextColor" />
            {{ $t('navigation.give_feedback') }}
          </NButton>

          <NButton
            v-if="isFeatureEnabled('intercom')"
            icon
            block
            quaternary
            class="justify-start px-2"
            @click="intercom.show"
          >
            <FaIcon icon="far fa-headset" class="mr-2" :color="defaultTextColor" />
            {{ $t('navigation.contact_support') }}
          </NButton>

          <!-- Version info -->
          <a v-if="!isProduction" :href="version.url" target="_blank" rel="noopener noreferrer">
            <NButton icon block quaternary class="justify-start px-2">
              <FaIcon icon="far fa-code-merge" class="mr-2" :color="defaultTextColor" />
              {{ version.label }}
            </NButton>
          </a>
        </Dropdown>

        <!-- User -->
        <Dropdown v-model="userDropdownVisible">
          <template #activator>
            <NButton text>
              <NAvatar :style="{ backgroundColor: primaryColor }" round>
                {{ user?.first_name[0] }}{{ user?.last_name[0] }}
              </NAvatar>
            </NButton>
          </template>

          <CRouterLink v-slot="{ navigate }" to="/settings">
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-cog" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.settings') }}
            </NButton>
          </CRouterLink>

          <CRouterLink v-slot="{ navigate }" to="/team">
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-user-group" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.team') }}
            </NButton>
          </CRouterLink>

          <CRouterLink v-slot="{ navigate }" to="/billing">
            <NButton icon block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-wallet" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.billing') }}
            </NButton>
          </CRouterLink>

          <CRouterLink v-slot="{ navigate }" to="/logout">
            <NButton block quaternary class="justify-start px-2" @click="navigate">
              <FaIcon icon="far fa-arrow-right-from-bracket" class="mr-2" :color="defaultTextColor" />
              {{ $t('navigation.logout') }}
            </NButton>
          </CRouterLink>
        </Dropdown>
      </div>
    </NCard>
  </NLayoutHeader>
</template>

<script setup lang="ts">
import Dropdown from '@/components/Dropdown.vue'
import SearchInput from '@/components/SearchInput.vue'
import { canUserSeeDebugFeatures } from '@/plugins/posthog'
import { computed, onMounted, ref } from 'vue'
import { getActiveFeedbackSurvey, isFeatureEnabled } from '@/plugins/posthog'
import { intercom } from '@/plugins/intercom'
import { useAuthStore, useThemeStore } from '@/store'
import { useEventBus, useEventSubscription } from '@/composables/useEventBus'
import type { Survey } from 'posthog-js'

const eventBus = useEventBus()
const user = useAuthStore().user
const themeStore = useThemeStore()

const hasInteractedWithSurvey = ref(false)
const feedbackSurvey = ref<Survey>()
const userDropdownVisible = ref(false)
const helpDropdownVisible = ref(false)
const primaryColor = themeStore.themeVars.primaryColor
const inLocalDevMode = import.meta.env.COMMAND === 'serve' && import.meta.env.MODE === 'development'

const defaultTextColor = computed(() => themeStore.themeVars?.textColor2 || '')

const openSurvey = () => {
  if (feedbackSurvey.value?.id) {
    eventBus.emit('render-survey', feedbackSurvey.value.id)
    hasInteractedWithSurvey.value = true
  }
}

const isProduction = import.meta.env.MODE === 'production'

const version = computed(() => {
  const url =
    import.meta.env.MODE === 'production'
      ? 'https://news.caplena.com/'
      : `https://github.com/caplena/caplena-app-next/commit/${import.meta.env.CAPP_VERSION}`
  const label = `${import.meta.env.MODE === 'production' ? 'v.' : 'commit:'} ${import.meta.env.CAPP_VERSION}`

  return { url, label }
})

const legacyRedirectUrl = () => {
  let redirectUrl = 'https://caplena.com/app'

  if (import.meta.env.MODE === 'development') {
    redirectUrl = 'https://legacy.dev.caplena.dev'
  } else if (import.meta.env.MODE === 'stage') {
    redirectUrl = 'https://legacy.stage.caplena.dev'
  }

  return redirectUrl
}

useEventSubscription('posthog-user-identified', () => {
  getActiveFeedbackSurvey((survey) => {
    feedbackSurvey.value = survey
  })
})

onMounted(() => {
  getActiveFeedbackSurvey((survey) => {
    feedbackSurvey.value = survey
  })

  const FIVE_MINUTES_MS = 5 * 60 * 1000

  // Show survey after 5 minutes if user hasn't interacted
  setTimeout(() => {
    if (!hasInteractedWithSurvey.value) {
      openSurvey()
    }
  }, FIVE_MINUTES_MS)
})
</script>

<style lang="scss" scoped>
.app-bar-layout {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0 16px;
  border-top-left-radius: 12px;
  border-bottom: 1px solid var(--divider-color);

  .app-bar-actions {
    width: auto;
    margin-left: auto;
    overflow-y: auto;

    :deep(.n-card__content) {
      display: flex;
      align-items: center;
      padding: 8px 16px;
    }
  }
}
</style>
