// Utility helpers for filters, only pure functions here no stores or api calls

import {
  AuxiliaryFieldUIResponse,
  type DateDynamicRangeFilterResponse,
  DateDynamicRangeKind,
  type DateValueBetweenFilterResponse,
  FilterKind,
  type GenericFilterRequest,
  type GenericFilterResponse,
  type NumericalValueBetweenFilterResponse,
  TextToAnalyzeFieldUIResponse,
  type TtaCategoryTopicSentimentEqualsFilterResponse,
  type TtaIsEmptyFilterResponse,
} from '@/api'
import { EFilterType, type TFilterConfig, type TPossibleFilterValue } from '@/components/filters'
import { getStartEndDateRangeStrings } from '@/utils'

export type TGenericFilterWithResponse = GenericFilterResponse & { loading?: boolean }

const FILTER_KEY_SEPERATOR = '|'

export const decodeFilterKey = (key: string) => {
  const [kind, columnRef] = key.split(FILTER_KEY_SEPERATOR)

  return {
    kind,
    columnRef,
  } as {
    kind: FilterKind
    columnRef: string
  } // casting is needed here since it is not possible to get the type of 'kind' out of encoded key
}

export const encodeFilterKey = (kind: `${FilterKind}`, columnRef: string) => {
  return `${kind}${FILTER_KEY_SEPERATOR}${columnRef}`
}

const isKindAndValueTypesMatch = (kind: GenericFilterRequest['kind'], value: TPossibleFilterValue) => {
  // nullish check is needed here
  // eslint-disable-next-line eqeqeq
  if (value == null) return true // if value is undefined or null, it is always valid

  if (!kind) return false

  const numberArrayValueKinds: GenericFilterRequest['kind'][] = [
    FilterKind.DATE_VALUE_BETWEEN,
    FilterKind.NUMERICAL_VALUE_BETWEEN,
  ]
  const stringArrayValueKinds: GenericFilterRequest['kind'][] = [
    FilterKind.TTA_CATEGORY_TOPIC_EQUALS,
    FilterKind.TTA_CATEGORY_EQUALS,
    FilterKind.TEXT_VALUE_EQUALS,
    FilterKind.TTA_SENTIMENT_EQUALS,
  ]
  const booleanValueKinds: GenericFilterRequest['kind'][] = [FilterKind.TTA_IS_REVIEWED, FilterKind.BOOL_VALUE_EQUALS]
  const textValueKinds: GenericFilterRequest['kind'][] = [
    FilterKind.TEXT_VALUE_CONTAINS,
    FilterKind.TTA_VALUE_EQUALS,
    FilterKind.DATE_DYNAMIC_RANGE,
  ]

  if (numberArrayValueKinds.includes(kind))
    return Array.isArray(value) && (typeof value[0] === 'number' || value.length === 0)
  if (stringArrayValueKinds.includes(kind))
    return Array.isArray(value) && (typeof value[0] === 'string' || value.length === 0)
  if (booleanValueKinds.includes(kind))
    return (typeof value === 'string' && (value === 'false' || value === 'true')) || typeof value === 'boolean'
  if (textValueKinds.includes(kind))
    return typeof value === 'string' || (Array.isArray(value) && (typeof value[0] === 'string' || value.length === 0))

  return false
}

export const createFilterRequest = (
  kind: GenericFilterRequest['kind'],
  columnRef: string,
  value?: TPossibleFilterValue,
  isInverted?: boolean,
  isEmpty?: boolean
): GenericFilterRequest => {
  // TO DO: make sure this never happens
  if (kind === FilterKind.TTA_CATEGORY_SENTIMENT_EQUALS || kind === FilterKind.TTA_CATEGORY_TOPIC_SENTIMENT_EQUALS)
    throw new Error(`This filter kind is not supported; ${kind}`)

  if (!isKindAndValueTypesMatch(kind, value)) {
    // type check here so that we can cast value of type correctly
    throw new Error(
      `Wrong value type for filter kind: ${kind}, type of value: ${Array.isArray(value) ? 'array' : typeof value}`
    )
  }

  // Special case for empty filters, by sending the kind and column_ref only the backend knows it is an empty filter and sets it's values approprietly, otherwise we would have to send null values for each filter type separately
  if (isEmpty) {
    return {
      kind,
      value: null,
      column_ref: columnRef,
    } as GenericFilterRequest
  }

  if (kind === FilterKind.TTA_IS_EMPTY) {
    return {
      kind,
      column_ref: columnRef,
    }
  }

  if (kind === FilterKind.DATE_VALUE_BETWEEN) {
    const [start, end] = isRangeValue(value) ? getStartEndDateRangeStrings(value) : ['', '']

    return {
      kind,
      column_ref: columnRef,
      start_date: start,
      end_date: end,
    }
  }

  if (kind === FilterKind.NUMERICAL_VALUE_BETWEEN) {
    const [min, max] = isRangeValue(value) ? value : [0, 0]

    return {
      kind,
      column_ref: columnRef,
      max_value: max,
      min_value: min,
    }
  }

  if (
    (kind === FilterKind.TEXT_VALUE_CONTAINS || kind === FilterKind.TTA_VALUE_EQUALS) &&
    Array.isArray(value) &&
    typeof value[0] === 'string'
  ) {
    return {
      kind,
      column_ref: columnRef,
      value: value[0],
    }
  }

  if (kind === FilterKind.DATE_DYNAMIC_RANGE) {
    const v = isDateDynamicRangeValue(value) ? value : DateDynamicRangeKind.ALL_TIME

    return {
      kind,
      column_ref: columnRef,
      value_range: v,
    }
  }

  return {
    kind,
    column_ref: columnRef,
    // type of value has been checked at the beginning of this function
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: (value ?? []) as any,
    is_inverted: isInverted,
  }
}

// this is a generic prop field - we need to have any here
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TFilterOptions = { type: TFilterConfig['type']; props?: Record<string, any>; labelTranslationKey?: string }

export const mapFilterType = (kind: FilterKind): TFilterOptions => {
  const filterKindDict: Record<FilterKind, TFilterOptions> = {
    [FilterKind.DATE_VALUE_BETWEEN]: { type: EFilterType.Date },
    [FilterKind.NUMERICAL_VALUE_BETWEEN]: { type: EFilterType.Range },
    [FilterKind.TTA_IS_EMPTY]: { type: EFilterType.Checkbox, props: { multiple: false } },
    [FilterKind.TTA_IS_REVIEWED]: { type: EFilterType.Checkbox, props: { multiple: false } },
    [FilterKind.BOOL_VALUE_EQUALS]: { type: EFilterType.Checkbox, props: { multiple: false } },
    [FilterKind.TEXT_VALUE_CONTAINS]: { type: EFilterType.SearchInput },
    [FilterKind.TEXT_VALUE_EQUALS]: { type: EFilterType.Checkbox, props: { multiple: true } },
    [FilterKind.TTA_CATEGORY_EQUALS]: { type: EFilterType.Checkbox, props: { multiple: true } },
    [FilterKind.TTA_SENTIMENT_EQUALS]: { type: EFilterType.Checkbox, props: { multiple: true } },
    [FilterKind.DATE_DYNAMIC_RANGE]: {
      type: EFilterType.Checkbox,
      labelTranslationKey: 'reports.report_detail.report_filters.date_dynamic_range',
      props: { multiple: false },
    },
    [FilterKind.TTA_CATEGORY_TOPIC_EQUALS]: { type: EFilterType.SearchInput },
    [FilterKind.TTA_VALUE_EQUALS]: { type: EFilterType.SearchInput },
    [FilterKind.TTA_CATEGORY_TOPIC_SENTIMENT_EQUALS]: { type: EFilterType.SearchInput },
    [FilterKind.TTA_CATEGORY_SENTIMENT_EQUALS]: { type: EFilterType.SearchInput },
    [FilterKind.TTA_IS_HIGHLIGHTED]: { type: EFilterType.Checkbox, props: { multiple: false } },
  }

  return filterKindDict[kind] || EFilterType.SearchInput
}

export const getFilterValue = (filter: GenericFilterResponse): TPossibleFilterValue => {
  if (isEmptyFilter(filter)) return null

  let value

  if (isCategoryTopicSentimentEqualsFilterResponse(filter))
    throw new Error(`This filter kind is not supported; ${filter.kind}`)

  // get value from filter depending on kind
  if (isTtaIsEmptyFilterResponse(filter)) value = undefined
  else if (isDateValueBetweenFilterResponse(filter))
    value =
      filter.start_date && filter.end_date
        ? [new Date(filter.start_date).getTime(), new Date(filter.end_date).getTime()]
        : []
  else if (isNumericalValueBetweenFilterResponse(filter))
    value =
      typeof filter.min_value === 'number' && typeof filter.max_value === 'number'
        ? [filter.min_value, filter.max_value]
        : []
  else if (isDateDynamicRangeFilterResponse(filter)) value = filter.value_range
  else value = filter.value

  return value
}

export const isEmptyFilter = (filter: GenericFilterResponse): boolean => {
  if (isCategoryTopicSentimentEqualsFilterResponse(filter))
    throw new Error(`This filter kind is not supported; ${filter.kind}`)

  if (isTtaIsEmptyFilterResponse(filter)) return false

  if (isDateValueBetweenFilterResponse(filter)) return !filter.start_date && !filter.end_date

  if (isNumericalValueBetweenFilterResponse(filter)) return filter.min_value === null && filter.max_value === null

  if (isDateDynamicRangeFilterResponse(filter)) return filter.value_range === null

  return filter.value === null
}

// TYPE GUARDS FOR FILTERS
export const isAuxiliaryField = (
  field: TextToAnalyzeFieldUIResponse | AuxiliaryFieldUIResponse
): field is AuxiliaryFieldUIResponse => field.type !== TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE

export const isTtaIsEmptyFilterResponse = (filter: GenericFilterResponse): filter is TtaIsEmptyFilterResponse =>
  filter.kind === FilterKind.TTA_IS_EMPTY

export const isDateValueBetweenFilterResponse = (
  filter: GenericFilterResponse
): filter is DateValueBetweenFilterResponse => filter.kind === FilterKind.DATE_VALUE_BETWEEN

export const isDateDynamicRangeFilterResponse = (
  filter: GenericFilterResponse
): filter is DateDynamicRangeFilterResponse => filter.kind === FilterKind.DATE_DYNAMIC_RANGE

export const isNumericalValueBetweenFilterResponse = (
  filter: GenericFilterResponse
): filter is NumericalValueBetweenFilterResponse => filter.kind === FilterKind.NUMERICAL_VALUE_BETWEEN

export const isRangeValue = (value: TPossibleFilterValue): value is [number, number] =>
  Array.isArray(value) && ((value.length === 2 && typeof value[0] === 'number') || value.length === 0)

export const isDateDynamicRangeValue = (
  value: TPossibleFilterValue
): value is DateDynamicRangeFilterResponse['value_range'] => typeof value === 'string'

export const isCategoryTopicSentimentEqualsFilterResponse = (
  filter: GenericFilterResponse
): filter is TtaCategoryTopicSentimentEqualsFilterResponse =>
  filter.kind === FilterKind.TTA_CATEGORY_TOPIC_SENTIMENT_EQUALS
