/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum FilterScope {
  REPORTS = 'reports',
  INSIGHT_ELEMENTS = 'insight-elements',
  REPORT_VIEWS = 'report-views',
  SEGMENTS = 'segments',
  PROJECT_DATA = 'project-data',
  TOPIC_ASSIGNMENTS = 'topic-assignments',
  ALERT_ON_TOPIC_TRENDS = 'alert-on-topic-trends',
}
